import type { ReactNode, MouseEvent } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Tooltip, Typography } from 'antd';

const { Paragraph } = Typography;

interface MenuItemButtonProps {
    tooltip: string | ReactNode;
    icon?: ReactNode;
    id?: string;
    onClick: (event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => void;
}

const ButtonWithTooltip = (props: MenuItemButtonProps) => {
    return <Tooltip
        placement='top'
        mouseEnterDelay={1}
        title={props.tooltip}
    >
        <Button
            type='text'
            size='small'
            id={props.id}
            onClick={props.onClick}>
            {props.icon}
        </Button>
    </Tooltip>;
};

interface TextWithTooltipProps {
    tooltip?: string | ReactNode;
    editing?: boolean;
    children: ReactNode
    onEdit?: (value: string) => void;
    onEditDone?: () => void;
}

const TextWithTooltip = (props: TextWithTooltipProps) => {
    return <Paragraph
        ellipsis={{
            tooltip: props.tooltip && {
                placement: 'top',
                mouseEnterDelay: 1.5,
                title: props.tooltip,
            },
        }}
        editable={{
            triggerType: [],
            editing: props.editing,
            onChange: props.onEdit,
            onEnd: props.onEditDone,
            onCancel: props.onEditDone,
            autoSize: { maxRows: 1 },
            enterIcon: null,
        }}
        style={{
            margin: 0,
            overflow: 'hidden',
            width: '100%',
            marginLeft: props.editing ? 12 : 0,
        }}
    >
        {props.children}
    </Paragraph>;
}

export interface MenuItemProps {
    label: string | ReactNode;
    actions: {
        key: string;
        label: string;
        id?: string;
        icon?: ReactNode;
        danger?: boolean;
    }[];
    editing?: boolean;
    onClickAction?: (key: string) => void;
    onDoubleClick?: () => void;
    onEdit?: (value: string) => void;
    onEditDone?: () => void;
}

export const MenuItem = (props: MenuItemProps) => {
    return <div
        style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        }}
        onDoubleClick={props.onDoubleClick}
        onBlur={props.onEditDone}
    >
        <TextWithTooltip
            tooltip={props.label}
            editing={props.editing}
            onEdit={props.onEdit}
            onEditDone={props.onEditDone}
        >
            {props.label}
        </TextWithTooltip>
        {props.actions?.length !== 0 && !props.editing && <span onClick={event => event.stopPropagation()}>
            {props.actions.length > 1
                ? <Dropdown
                    placement='bottomLeft'
                    menu={{
                        items: props.actions.map(action => ({
                            key: action.key,
                            danger: action.danger,
                            label:
                                <span id={action.id}>
                                    <span style={{ marginRight: 12 }}>
                                        {action.icon}
                                    </span>
                                    {action.label}
                                </span>,
                        })),
                        onClick: ({ key }) => props.onClickAction?.(key),
                    }}
                >
                    <Button
                        type='text'
                        size='small'
                        icon={<MoreOutlined />}
                        style={{ marginLeft: 4 }}
                    />
                </Dropdown>
                : <ButtonWithTooltip
                    tooltip={props.actions[0].label}
                    icon={props.actions[0].icon}
                    id={props.actions[0].id}
                    onClick={() => props.onClickAction?.(props.actions[0].key)}
                />
            }
        </ span>
        }
    </div>
};

export default MenuItem;