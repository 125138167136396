
interface DBDocument<T> {
    id: string;
    document: T;
}

export class PersistentMemoryDB {

    private readonly openRequest: IDBOpenDBRequest;
    private readonly db: Promise<IDBDatabase>;
    private readonly items = new Map<string, unknown>();
    private readonly populated: Promise<void>;

    constructor(
        private readonly containerName: string,
        private readonly keys: string[] = ['id'],
    ) {
        this.openRequest = window.indexedDB.open( `PersistentMemoryDB-${this.containerName}`, 3);
        this.db = new Promise<IDBDatabase>((resolve, reject) => {
            this.openRequest.onsuccess = () => {
                resolve(this.openRequest.result);
            };
            this.openRequest.onerror = () => {
                reject(this.openRequest.error);
            };
            this.openRequest.onupgradeneeded = () => {
                const db = this.openRequest.result;
                db.createObjectStore(this.containerName, { keyPath: this.keys });
            };
        });
        this.populated = this.populateDB();
    }

    public isReady(): Promise<void> {
        return this.populated;
    }

    public getAll<T>() {
        return Array.from(this.items.values()) as T[];
    }

    public get<T>(key: string): T {
        return this.items.get(key) as T;
    }

    public async set<T>(key: string, value: T): Promise<void> {
        const container = await this.getContainer('readwrite');
        const request = container.put({ id: key, document: value });
        return new Promise((resolve, reject) => {
            request.onsuccess = () => {
                this.items.set(key, value);
                resolve();
            };
            request.onerror = () => {
                reject(request.error);
            };
        });
    }

    public async delete(key: string): Promise<void> {
        const container = await this.getContainer('readwrite');
        const request = container.delete([key]);
        return new Promise((resolve, reject) => {
            request.onsuccess = () => {
                this.items.delete(key);
                resolve();
            };
            request.onerror = () => {
                reject(request.error);
            };
        });
    }

    private async populateDB() {
        const container = await this.getContainer();
        const request = container.getAll();
        return new Promise<void>((resolve, reject) => {
            request.onsuccess = () => {
                const result = request.result as DBDocument<unknown>[];
                for (const item of result) {
                    this.items.set(item.id, item.document);
                }
                resolve();
            };
            request.onerror = () => {
                reject(request.error);
            };
        });
    }

    private async getContainer(mode?: IDBTransactionMode): Promise<IDBObjectStore> {
        const db = await this.db;
        const transaction = db.transaction(this.containerName, mode || 'readonly');
        return transaction.objectStore(this.containerName);
    }
}