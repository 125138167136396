import type { default as OpenAI } from 'openai';
import type { Stream } from 'openai/streaming';

export interface ToolCall {
    id: string;
    index: number;
    name: string;
    args?: string;
}

export interface Completion {
    content?: string;
    toolCalls?: ToolCall[];
    finishReason?: OpenAI.Chat.Completions.ChatCompletionChunk.Choice['finish_reason'];
}

export interface CompletionHandler {
    onContentStart?: () => void | Promise<void>;
    onContentChunk?: (contentDelta: string, accumulatedContent: string) => void | Promise<void>;
    onContentEnd?: (accumulated?: string) => void | Promise<void>;
    onFunctionStart?: (name: string, id: string) => void | Promise<void>;
    onFunctionArgsChunk?: (argDelta: string, accumulatedArgs: string, id: string) => void | Promise<void>;
    onFunctionCall?: (call: ToolCall) => void | Promise<void>;
    onStop?: (completion?: Completion) => void | Promise<void>;
}

export const handleCompletion = async (
    stream: Stream<OpenAI.Chat.Completions.ChatCompletionChunk>,
    handlers: CompletionHandler = {},
) => {
    const completion: Completion = {};
    let lastKind: 'content' | 'tool' | undefined = undefined;
    let lastToolId: string | undefined = undefined;
    let lastToolIndex: number | undefined = undefined;
    handlers = Object.getOwnPropertyNames(handlers).reduce((acc, key) => {
        const handler = handlers[key as keyof CompletionHandler];
        if (handler) {
            acc[key as keyof CompletionHandler] = async (...args: unknown[]) => {
                console.log('handleCompletion', key, ...args);
                try {
                    await (handler as any)(...args);
                } catch (err) {
                    console.error('Error in handler', key, args, err);
                }
            };
        }
        return acc;
    }, {} as CompletionHandler);
    const calledToolIndices = new Set<number>();
    for await (const chunk of stream) {
        const choice = chunk.choices[0];
        console.log('choice', choice);
        if (choice.delta.content != null) {
            if (lastKind !== 'content') {
                await handlers.onContentStart?.();
            }

            // tool ended, notify previous call
            if (lastKind === 'tool' && (completion.toolCalls?.length ?? 0) > 1 && lastToolIndex != null) {
                calledToolIndices.add(lastToolIndex);
                await handlers.onFunctionCall?.(
                    completion.toolCalls![lastToolIndex],
                );
            }

            completion.content = (completion.content || '') + choice.delta.content;
            await handlers.onContentChunk?.(
                choice.delta.content,
                completion.content,
            );

            lastKind = 'content';
            lastToolId = undefined;
            lastToolIndex = undefined;
        }
        if (choice.delta.tool_calls) {
            if (lastKind === 'content') {
                await handlers.onContentEnd?.(completion.content);
            }
            const toolCall = choice.delta.tool_calls[0];

            // TODO: support other tools when available
            if (toolCall.type !== 'function' && !('function' in toolCall)) {
                console.info('Unsupported tool call type', toolCall);
                lastKind = 'tool';
                continue;
            }

            // tool started or tool changed, notify new start
            if (lastKind !== 'tool' || lastToolId !== toolCall.id) {
                if (toolCall.function?.name) {
                    await handlers.onFunctionStart?.(toolCall.function.name, toolCall.id!);
                    completion.toolCalls ||= [];
                    console.assert(!completion.toolCalls[toolCall.index], `tool call already exists at index ${toolCall.index}`, completion.toolCalls[toolCall.index]);
                    console.assert(toolCall.id, 'tool call from open ai is expected to have an id on the first delta', toolCall);
                    completion.toolCalls[toolCall.index] = {
                        id: toolCall.id!,
                        index: toolCall.index,
                        name: toolCall.function.name,
                    };
                }
            }

            // tool changed, notify previous call
            if (lastKind === 'tool' && lastToolIndex != null && lastToolIndex !== toolCall.index) {
                const lastToolCall = completion.toolCalls?.[lastToolIndex!];
                if (lastToolCall) {
                    calledToolIndices.add(lastToolIndex);
                    await handlers.onFunctionCall?.(lastToolCall);
                }
            }

            // chunk args
            if (toolCall.function?.arguments) {
                if (completion.toolCalls?.[toolCall.index]) {
                    completion.toolCalls[toolCall.index].args ||= '';
                    completion.toolCalls[toolCall.index].args += toolCall.function.arguments;
                }
                await handlers.onFunctionArgsChunk?.(
                    toolCall.function?.arguments,
                    completion.toolCalls?.[toolCall.index]?.args ?? '',
                    toolCall.id!,
                );
            }

            lastKind = 'tool';
            lastToolId = toolCall.id;
            lastToolIndex = toolCall.index;
        }

        if (choice.finish_reason) {
            completion.finishReason = choice.finish_reason;
            if (completion.finishReason === 'tool_calls' && completion.toolCalls) {
                for (const toolCall of completion.toolCalls) {
                    if (!calledToolIndices.has(toolCall.index)) {
                        await handlers.onFunctionCall?.(toolCall);
                    }
                }
            }
            await handlers.onStop?.(completion);
            if (lastKind === 'content') {
                await handlers.onContentEnd?.(completion.content);
            }
        }
    }

    return completion;
};
