import { ConfigProvider, ThemeConfig, theme } from 'antd';
import { App } from 'antd';
import {
    createBrowserRouter,
    RouterProvider,
    Navigate,
    useParams,
    Outlet,
} from 'react-router-dom';

import { store } from './app/store';
import Shell from './components/app-shell/AppShell';
import ChatPage from './components/chat/Chat';
import { loadChat } from './components/chat/Chat.slice';
import Settings from './components/settings/Settings';
import { loadSettings } from './components/settings/Settings.slice';
import { OpenAIProvider } from './modules/openai';
import AgentsPage from './components/agent/Agent';
import { PythonProvider } from './modules/python';

const ChatFromUrl = () => {
    const { id } = useParams();
    return <ChatPage id={id as string} />;
};

const AgentFromUrl = () => {
    const { id } = useParams();
    return <AgentsPage id={id as string} />;
}

const router = createBrowserRouter([
    {
        element: <Shell><Outlet /></Shell>,
        children: [{
            path: "/",
            loader: () => store.dispatch(loadSettings()),
            children: [
                {
                    path: "chat",
                    children: [
                        {
                            path: ":id",
                            element: <ChatFromUrl />,
                            loader: args => store.dispatch(loadChat(args.params!.id!)),
                        },
                        {
                            path: "",
                            element: <ChatPage />,
                        },
                    ],
                },
                {
                    path: "assistants",
                    children: [
                        {
                            path: ":id",
                            element: <AgentFromUrl />,
                        },
                        {
                            path: "",
                            element: <AgentsPage />,
                        },
                    ],
                },
                {
                    path: "tools",
                    element: <div>Tools</div>,
                },
                {
                    path: "settings",
                    element: <Settings />,
                },
                {
                    path: "",
                    element: <Navigate to="/chat?agent=omni" replace={true} />,
                },
            ],
        }],
    },
]);

const themeConfig: ThemeConfig = {
    algorithm: [theme.darkAlgorithm],
};

const OmninceApp = () => {
    return (
        <ConfigProvider theme={themeConfig}>
            <PythonProvider>
                <OpenAIProvider>
                    <App>
                        <RouterProvider router={router} />
                    </App>
                </OpenAIProvider>
            </PythonProvider>
        </ConfigProvider>
    );
}

export default OmninceApp;
