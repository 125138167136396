import { type ReactNode, createContext, useContext } from 'react';
import {
    type ToolRef,
    createOmninceEnvironment,
    parseToolsFromAST,
    getGlobals,
    installPackages,
    getComments,
} from '../helpers/pyodide';
import type { PyodideInterface } from 'pyodide';
import type { PyProxy } from 'pyodide/ffi';

interface PythonEnvironment {
    readonly id: string;
    readonly instance: {
        readonly pyodide: PyodideInterface;
        readonly toolRefs: Map<string, ToolRef>;
        readonly hooks: {
            readonly modifyMessage?: ((messages: any[]) => any[] & PyProxy) & PyProxy;
        };
    };
    readonly modules: Record<string, any>; // These modules are dynamic submodules on the omnince module and can be hot-swapped at runtime.
}

export class Python {

    private readonly environments = new Map<string, PythonEnvironment>();

    public async getEnvironment(
        id: string,
        agentId: string,
    ) {
        const environment: PythonEnvironment = this.environments.get(id) || {
            id,
            instance: await createOmninceEnvironment(id, agentId, undefined, () => environment.modules),
            modules: {},
        };
        this.environments.set(id, environment);
        return environment;
    }

    public async getActiveTools(envId: string, agentId: string, code: string) {
        const env = await this.getEnvironment(envId, agentId);
        const { instance: { pyodide, toolRefs } } = env;

        for (const toolRef of toolRefs.values()) {
            toolRef.predicate?.destroy();
            toolRef.instance.destroy();
        }
        toolRefs.clear();

        const codeRunner = pyodide.pyodide_py.code.CodeRunner(code);
        const comments = await getComments(pyodide, code);
        const toolDefs = new Map(parseToolsFromAST(codeRunner.ast, comments).map(toolDef => [toolDef.name, toolDef]));
        console.log('toolDefs', toolDefs);

        let start = performance.now();
        const installResult = await installPackages(pyodide, code);
        if (!installResult.success) {
            console.error('installPackages', installResult, ...(installResult.errorMessages || []));
            throw new Error('Failed to install all packages. ' + installResult.errorMessages?.join('\n'));
        }
        console.log('load packages', performance.now() - start, pyodide.loadedPackages);

        start = performance.now();
        codeRunner.compile();
        console.log('compile', performance.now() - start);

        start = performance.now();
        const result = await codeRunner.run_async(getGlobals(pyodide));
        console.log('run', performance.now() - start);
        console.log('result', result);

        for (const toolRef of toolRefs.values()) {
            toolRef.definition = toolDefs.get(toolRef.name);
            if (!toolRef.definition) {
                console.error(`Tool definition not found for tool ref: ${toolRef.name}`, toolRef);
            }
        }
        console.log('toolRefs', toolRefs);

        return [...toolRefs.values()].filter(toolRef => {
            try {
                return toolRef.predicate ? toolRef.predicate() : true;
            } catch (e) {
                console.error('toolRef.predicate', toolRef.name, e);
                return false;
            }
        });
    }

    public async parseTools(envId: string, agentId: string, code: string) {
        const env = await this.getEnvironment(envId, agentId);
        const { instance: { pyodide } } = env;
        const codeRunner = pyodide.pyodide_py.code.CodeRunner(code);
        const comments = await getComments(pyodide, code);
        return parseToolsFromAST(codeRunner.ast, comments);
    }
}

const python = new Python();

export const PythonContext = createContext<Python>(python);

interface PythonProviderProps {
    children: ReactNode;
}

export const PythonProvider = ({ children }: PythonProviderProps) => {
    return (
        <PythonContext.Provider value={python}>
            {children}
        </PythonContext.Provider>
    );
};

export const usePython = () => useContext(PythonContext);

export default usePython;
