
export const register = () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js`).then(
            (registration) => {
                registration.update();
                console.log('ServiceWorker registration successful');
            },
            (err) => {
                console.log('ServiceWorker registration failed', err);
            }
        );
    }
};