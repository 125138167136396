import { useState, type ReactNode, useMemo, useEffect, memo, useCallback } from 'react';
import {
    CommentOutlined,
    DeleteOutlined,
    EditOutlined,
    ExportOutlined,
    PlusOutlined,
    SettingOutlined,
    RobotOutlined,
} from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex, message, Tour, Typography } from 'antd';
import type { ItemType } from 'antd/es/menu/hooks/useItems';

import Shell from '../shell/Shell';
import { useAppDispatch, useMemoSelector } from '../../app/hooks';
import { deleteChat, loadChats, makeSelectAllChats, updateChat } from '../chat/Chat.slice';
import MenuItem from '../menu-item/MenuItem';
import { createAgent, loadAgents, makeSelectAllAgents } from '../agent/Agent.slice';
import type { Agent } from '../../contracts/agent';
import { deleteAgent } from '../agent/Agent.slice';

const { Text, Title } = Typography;

export interface AppShellProps {
    children?: ReactNode;
}

const AgentLabel = memo(({ agent }: { agent: SideNavAgent }) => <Flex gap={8} align='center'>
    {agent.avatarUrl
        ? <img
            src={agent.avatarUrl}
            alt="Avatar"
            style={{
                width: 22,
                height: 22,
                borderRadius: '50%',
                overflow: 'hidden',
            }}
        />
        : <RobotOutlined style={{ fontSize: 18, margin: '0 2px' }} />
    }
    {agent.name}
</Flex>);

interface SideNavAgent {
    id: string;
    avatarUrl?: string;
    key: string;
    name: string;
}

interface AgentsSideNavItemsProps {
    agents: SideNavAgent[];
    hoveredKey?: string;
    setHoveredKey?: (key?: string) => void;
    onNewAgent?: () => void;
    onOpenAgent?: (agent: SideNavAgent) => void;
    onEditAgent?: (agent: SideNavAgent) => void;
    onDeleteAgent?: (agent: SideNavAgent) => void;
}

const showTour = localStorage.getItem('showTour') !== 'false';

const createAgentSideNavItem = (agent: SideNavAgent, props: AgentsSideNavItemsProps): ItemType => ({
    key: agent.key,
    label: <MenuItem
        label={<AgentLabel agent={agent} />}
        actions={props.hoveredKey === agent.key ? [
            {
                key: 'chat',
                label: 'Chat',
                icon: <CommentOutlined />,
            },
            {
                key: 'edit',
                label: 'Edit',
                icon: <EditOutlined />,
            },
            {
                key: 'delete',
                label: 'Delete',
                danger: true,
                icon: <DeleteOutlined />,
            },
        ] : []}
        onClickAction={async (key) => {
            if (key === 'chat') {
                props.onOpenAgent?.(agent);
            } else if (key === 'edit') {
                props.onEditAgent?.(agent);
            } else if (key === 'delete') {
                props.onDeleteAgent?.(agent);
            }
        }}
    />,
    onMouseEnter: () => props.setHoveredKey?.(agent.key),
    onMouseLeave: () => props.setHoveredKey?.(),
});

const omniAgent: SideNavAgent = {
    id: 'omni',
    avatarUrl: '/favicon.svg',
    key: 'agent/omni',
    name: 'Omni',
};

const createAgentsSideNavItems = (props: AgentsSideNavItemsProps): ItemType[] => [
    {
        type: 'group',
        key: 'agents',
        label: <div
            style={{
                display: 'flex',
                minHeight: 24,
                alignItems: 'center',
            }}
            onMouseEnter={() => props.setHoveredKey?.('agents')}
            onMouseLeave={() => props.setHoveredKey?.()}
        >
            <MenuItem
                label={<Text type='secondary'>Assistants</Text>}
                actions={[
                    {
                        key: 'new-agent',
                        label: 'New assistant',
                        id: 'new-agent',
                        icon: <Text type={props.hoveredKey === 'agents' ? undefined : 'secondary'}>
                            <PlusOutlined />
                        </Text>,
                    },
                ]}
                onClickAction={key => {
                    if (key === 'new-agent') {
                        props.onNewAgent?.();
                    }
                }}
            />
        </div>,
        children: [
            {
                key: 'agent/omni',
                label: <span id='omni-agent'>
                    <AgentLabel agent={omniAgent} />
                </span>,
            },
            ...props.agents.slice(0, 4).map(agent => createAgentSideNavItem(agent, props)),
            ...(props.agents.length > 4 ? [{
                key: 'more',
                label: 'More',
                children: props.agents.slice(4).map(agent => createAgentSideNavItem(agent, props)),
            }] : []),
        ],
    },
];

const AppShell = (props: AppShellProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const params = useParams();
    const [messageApi, contextHolder] = message.useMessage();
    const [hoveredKey, setHoveredKey] = useState<string | undefined>(undefined);
    const [editingChatId, setEditingChatId] = useState<string | undefined>(undefined);
    const chatInstances = useMemoSelector(makeSelectAllChats);
    const agents = useMemoSelector(makeSelectAllAgents);

    useEffect(() => {
        dispatch(loadChats());
        dispatch(loadAgents());
    }, [dispatch]);

    const agentsSideNavItems = useMemo(() => createAgentsSideNavItems({
        agents: agents.filter(agent => agent.entity).map(agent => ({
            id: agent.id,
            avatarUrl: agent.entity!.avatarUrl,
            key: `agent/${agent.id}`,
            name: agent.entity!.name,
        })),
        hoveredKey,
        setHoveredKey,
        onNewAgent: async () => {
            const agent = await dispatch(createAgent());
            navigate(`/assistants/${(agent.payload as Agent).id}`);
        },
        onOpenAgent: agent => {
            navigate(`/chat?agent=${agent.id}`);
        },
        onEditAgent: agent => {
            navigate(`/assistants/${agent.id}`);
        },
        onDeleteAgent: async (agent) => {
            await dispatch(deleteAgent(agent.id));
            messageApi.success(`Deleted "${agent.name}"`);
        },
    }), [agents, hoveredKey, dispatch, navigate, messageApi]);

    const chatSideNavItems = useMemo(() => chatInstances.map(chat => ({
        key: `chat/${chat.id}`,
        onMouseEnter: () => setHoveredKey(chat.id),
        onMouseLeave: () => setHoveredKey(undefined),
        label: <MenuItem
            label={chat.entity?.name || ''}
            editing={editingChatId === chat.id}
            actions={hoveredKey === chat.id ? [
                {
                    key: 'open',
                    label: 'Open',
                    icon: <ExportOutlined />,
                },
                {
                    key: 'rename',
                    label: 'Rename',
                    icon: <EditOutlined />
                },
                {
                    key: 'delete',
                    label: 'Delete',
                    danger: true,
                    icon: <DeleteOutlined />,
                },
            ] : []}
            onClickAction={async (key) => {
                if (key === 'open') {
                    navigate(`/chat/${chat.id}`);
                } else if (key === 'rename') {
                    setEditingChatId(chat.id);
                } else if (key === 'delete') {
                    navigate('/chat', { replace: true });
                    await dispatch(deleteChat(chat.id));
                    messageApi.success(`Deleted "${chat.entity?.name || ''}"`);
                }
            }}
            onDoubleClick={() => setEditingChatId(chat.id)}
            onEdit={(value) => {
                if (!chat.entity) {
                    return;
                }
                dispatch(updateChat({
                    ...chat.entity,
                    name: value,
                }));
            }}
            onEditDone={() => setEditingChatId(undefined)}
        />,
    } as ItemType)), [chatInstances, dispatch, editingChatId, hoveredKey, messageApi, navigate]);

    const sideNav = useMemo(() => ({
        selectedKeys: params.id ? [`chat/${params.id}`, `agent/${params.id}`] : [],
        items: [
            ...agentsSideNavItems,
            { type: 'divider' } as const,
            ...chatSideNavItems,
        ],
        onClick: (event: { key: string }) => {
            if (event.key.startsWith('agent/')) {
                const agentId = event.key.split('agent/')[1];
                if (agentId) {
                    navigate(`/chat?agent=${agentId}`);
                } else {
                    navigate('/chat');
                }
            } else if (event.key.startsWith('chat/')) {
                const chatId = event.key.split('chat/')[1];
                navigate(`/chat/${chatId}`);
            }
        },
    }), [agentsSideNavItems, chatSideNavItems, navigate, params.id]);

    const sideNavBottom = useMemo(() => ({
        selectedKeys: [],
        items: [
            { type: 'divider' } as const,
            {
                key: 'settings',
                label: <span id='settings-button'>Settings</span>,
                icon: <SettingOutlined />,
                style: {
                    display: 'flex',
                },
            },
        ],
        onClick: (event: { key: string }) => {
            if (event.key === 'settings') {
                navigate('/settings');
            }
        },
    }), [navigate]);

    const [openTour, setOpenTour] = useState(false);

    useEffect(() => {
        if (showTour) {
            setTimeout(() => setOpenTour(true), 3000);
        }
    }, []);

    const onFinishTour = useCallback(() => {
        localStorage.setItem('showTour', 'false');
        setOpenTour(false);
    }, []);

    return <Shell
        sideNav={sideNav}
        sideNavBottom={sideNavBottom}
    >
        {contextHolder}
        {props.children}
        {showTour && <Tour
            open={openTour}
            onFinish={onFinishTour}
            onClose={onFinishTour}
            mask={false}
            type='primary'
            animated={true}
            onChange={() => setHoveredKey('agents')}
            steps={[
                {
                    target: null,
                    title: <Title level={4} style={{ margin: 0 }}>Welcome to Omnince!</Title>,
                    placement: 'center',
                    description: <div>
                        <div>Omnince is a browser based virtual assistant platform built on OpenAI's platform.</div>
                        <div>Your conversations and data are <strong>never</strong> stored externally.</div>
                        <div>Create assistants that can utilize Python tools to do whatever you want!</div>
                        <div>Please take this brief tour of Omnince to get started.</div>
                    </div>,
                },
                {
                    target: () => document.querySelector('#settings-button')!,
                    title: 'Settings',
                    placement: 'rightBottom',
                    description: 'Get started by configuring your settings.',
                },
                {
                    target: () => document.querySelector('#omni-agent')!,
                    title: 'Assistants',
                    placement: 'right',
                    description: 'Create, manage, and chat with your assistants.',
                },
                {
                    target: () => document.querySelector('#new-agent')!,
                    title: 'Create an assistant',
                    placement: 'right',
                    description: 'Create a customized assistant to help you with specific tasks.',
                },
            ]}
        />}
    </Shell>;
};

export default AppShell;
