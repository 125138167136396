import type { loadPyodide as LoadPyodide, PyodideInterface } from 'pyodide';
import type { PyProxy } from 'pyodide/ffi';
import type { JSONSchema, PythonToolDefinition } from '../contracts/tools';
import { entityDb } from './entityDb';

declare const loadPyodide: typeof LoadPyodide;

const isPyProxy = (x: unknown): x is PyProxy => typeof x === 'object' && x?.constructor.name === 'PyProxy';

export const toJs = (value: PyProxy, withType = true): any => {
    if (!isPyProxy(value)) {
        if (Array.isArray(value)) {
            return (value as any[]).map(item => toJs(item, withType));
        }
        return value;
    }
    if (value.type === 'list' || value.type === 'tuple') {
        return (value as unknown as any[]).map(item => toJs(item, withType));
    }
    if (value.type === 'dict') {
        const obj: Record<string, any> = withType
            ? {
                __type__: 'dict',
            }
            : {};
        const props = value.keys();
        for (const name of props) {
            obj[name] = toJs(value.get(name), withType);
        }
        return obj;
    }
    const props = Object.getOwnPropertyNames(value).filter(x => !x.startsWith('__'));
    const obj: Record<string, any> = withType
        ? {
            __type__: value.type,
        }
        : {};
    for (const name of props) {
        obj[name] = toJs(value[name], withType);
    }
    return obj;
};

export type ToolResult = PyProxy;

export interface ToolRef {
    name: string;
    displayName?: string;
    description?: string;
    hidden?: boolean;
    definition?: PythonToolDefinition;
    predicate?: (() => boolean) & PyProxy;
    instance: ((...args: any[]) => ToolResult) & PyProxy;
}

interface TypeRef {
    kind: 'ref';
    name: string;
    node: PythonASTNode;
}

interface TypePrimary {
    kind: 'primary';
    type:
    | 'int' | 'float' | 'bool' | 'str'
    | 'list' | 'dict' | 'set' | 'tuple';
    node: PythonASTNode;
}

interface TypeOptional {
    kind: 'optional';
    type: Type;
    node: PythonASTNode;
}

interface TypeUnion {
    kind: 'union';
    types: Type[];
    node: PythonASTNode;
}

interface TypeList {
    kind: 'list';
    type: Type;
    node: PythonASTNode;
}

interface TypeDict {
    kind: 'dict';
    key: Type;
    value: Type;
    node: PythonASTNode;
}

interface TypeSet {
    kind: 'set';
    type: Type;
    node: PythonASTNode;
}

interface TypeTuple {
    kind: 'tuple';
    types: Type[];
    node: PythonASTNode;
}

interface TypeLiteral {
    kind: 'literal';
    value: string | number | true | false;
    node: PythonASTNode;
}

interface TypeEnum {
    kind: 'enum';
    values: TypeLiteral[];
    node: PythonASTNode;
}

interface TypeNone {
    kind: 'none';
    node: PythonASTNode;
}

interface TypeAny {
    kind: 'any';
    node: PythonASTNode;
}

interface TypeObject {
    kind: 'object';
    name?: string;
    fields: {
        name: string;
        type?: Type;
        node: PythonASTNode;
    }[];
    node: PythonASTNode;
}

type Type =
    | TypeRef
    | TypeOptional
    | TypeUnion
    | TypeList
    | TypeDict
    | TypeSet
    | TypeTuple
    | TypeNone
    | TypeObject
    | TypeEnum
    | TypeLiteral
    | TypePrimary
    | TypeAny;

// Python AST docs:
// https://docs.python.org/3/library/ast.html#module-ast

type PythonASTNode = any & {
    lineno: number;
    end_lineno: number;
    col_offset: number;
    end_col_offset: number;
};
const builtIns = new Set(['int', 'float', 'bool', 'str', 'list', 'dict', 'set', 'tuple', 'Any']);
const parseTypes = {
    ClassDef: (node: PythonASTNode) => ({
        kind: 'object',
        name: node.name,
        fields: node.body
            .filter((item: PythonASTNode) => item.__type__ === 'AnnAssign')
            .map((field: PythonASTNode) => ({
                name: field.target.id,
                type: field.annotation && parseType(field.annotation),
                node: field,
            })),
        node,
    }),
    Subscript: (node: PythonASTNode) => {
        const value = node.value.id.toLowerCase();
        const slice = parseType(node.slice);
        switch (value) {
            case 'optional': return {
                kind: value,
                type: slice,
                node,
            };
            case 'list': return {
                kind: value,
                type: slice,
                node,
            };
            case 'set': return {
                kind: value,
                type: slice,
                node,
            };
            case 'tuple': return {
                kind: value,
                types:
                    slice?.kind === 'union'
                        ? slice.types
                        : [slice],
                node,
            };
            case 'dict':
                const types = (slice as TypeUnion)?.types || []; // dict must be union like
                return {
                    kind: value,
                    key: types[0],
                    value: types[1],
                    node,
                };
            case 'union': return {
                kind: value,
                types: slice?.kind === 'union'
                    ? slice.types
                    : [slice],
                node,
            };
            case 'literal': return slice.kind === 'union'
                // a ref inside a literal can be interpreted as a string literal
                ? {
                    kind: 'enum',
                    values: slice.types.map((type: TypeRef | TypeLiteral) => ({
                        kind: 'literal',
                        value: type.kind === 'ref' ? type.name : type.value,
                    })),
                    node,
                }
                : {
                    kind: value,
                    value: slice.kind === 'ref' ? slice.name : slice.value,
                    node,
                };
        }
    },
    BinOp: (node: PythonASTNode) => ({
        kind: 'union',
        types: [parseType(node.left), parseType(node.right)]
            .map(type => type.kind === 'union' ? type.types : type)
            .flat(Infinity),
        node,
    }),
    Tuple: (node: PythonASTNode) => ({
        kind: 'union',
        types: node.elts.map(parseType).flat(Infinity),
        node,
    }),
    Name: (node: PythonASTNode) => builtIns.has(node.id)
        ? node.id === 'Any'
            ? {
                kind: 'any',
                node,
            }
            : {
                kind: 'primary',
                type: node.id,
                node,
            }
        : {
            kind: 'ref',
            name: node.id,
            node,
        },
    Constant: (node: PythonASTNode) =>
        typeof node.value === 'string' ? {
            kind: 'ref',
            name: node.value,
            node,
        }
            : node.value === undefined ? {
                kind: 'none',
                node,
            }
                : typeof node.value === 'object' ? parseType(node.value) // handles the specific case of ellipsis
                    : {
                        kind: 'literal',
                        value: node.value,
                        node,
                    },
    ellipsis: () => [],
} as const;
export const parseType = (node: PythonASTNode): PythonASTNode | undefined => parseTypes[node.__type__ as keyof typeof parseTypes]?.(node);

const getTypeComment = (comments: Map<number, PyToken[]>, node: PythonASTNode) => {
    return comments
        .get(node.lineno)
        ?.map(comment => (comment[1] || '')
            .substring((comment[1]?.indexOf('#') ?? -1) + 1)
            .trim(),
        )
        ?.join('\n');
};

const addDescription = (comments: Map<number, PyToken[]>, node: PythonASTNode, schema: JSONSchema) => {
    const comment = getTypeComment(comments, node);
    if (comment) {
        schema.description = comment;
    }
    return schema;
};

export const typeToJSONSchema = (
    type: Type,
    typeRefs: Map<string, Type>,
    definitions: Record<string, JSONSchema>,
    comments: Map<number, PyToken[]>,
): JSONSchema => {
    switch (type.kind) {
        case 'ref':
            if (!typeRefs.has(type.name)) {
                throw new Error(`Referenced type '${type.name}' not found`);
            }
            if (!definitions[type.name]) {
                const typeRef = typeRefs.get(type.name);
                if (typeRef) {
                    const schema = typeToJSONSchema(typeRef, typeRefs, definitions, comments);
                    if (!definitions[type.name] && !schema.$ref) {
                        // type alias, add to definitions - there's nowhere else that can add it
                        definitions[type.name] = schema;
                    }
                } else {
                    throw new Error(`Expected type ref ${type.name}, but none was found.`);
                }
            }
            return { $ref: `#/$defs/${type.name}` };

        case 'primary':
            return addDescription(
                comments,
                type.node,
                mapPrimaryType(type.type),
            );

        case 'optional':
            return addDescription(
                comments,
                type.node,
                { anyOf: [{ type: 'null' }, typeToJSONSchema(type.type, typeRefs, definitions, comments)] },
            );

        case 'union':
            return addDescription(
                comments,
                type.node,
                { anyOf: type.types.map(t => typeToJSONSchema(t, typeRefs, definitions, comments)) },
            );

        case 'enum':
            return addDescription(
                comments,
                type.node,
                { enum: type.values.map(v => v.value) },
            );

        case 'literal':
            return addDescription(
                comments,
                type.node,
                { const: type.value },
            );

        case 'list':
            return addDescription(
                comments,
                type.node,
                { type: 'array', items: typeToJSONSchema(type.type, typeRefs, definitions, comments) },
            );

        case 'dict':
            return addDescription(
                comments,
                type.node,
                { type: 'object', additionalProperties: typeToJSONSchema(type.value, typeRefs, definitions, comments) },
            );

        case 'set':
            return addDescription(
                comments,
                type.node,
                { type: 'array', items: typeToJSONSchema(type.type, typeRefs, definitions, comments), uniqueItems: true },
            );

        case 'tuple':
            return addDescription(
                comments,
                type.node,
                { type: 'array', items: type.types.map(t => typeToJSONSchema(t, typeRefs, definitions, comments)) },
            );

        case 'object':
            const properties: { [key: string]: JSONSchema } = {};
            const required: string[] = [];
            const shouldAddDefinition = type.name && !definitions[type.name];
            if (shouldAddDefinition) {
                definitions[type.name as string] = {}; // placeholder
            }
            for (const field of type.fields) {
                const type = field.type;
                const isOptional = type && type.kind === 'optional';
                if (!isOptional) {
                    required.push(field.name);
                }
                properties[field.name] = type
                    ? typeToJSONSchema(isOptional ? type.type : type, typeRefs, definitions, comments)
                    : { type: 'any' };
                addDescription(
                    comments,
                    type?.node || field.node,
                    properties[field.name],
                );
            }

            const object = {
                type: 'object',
                properties,
                ...(required.length > 0 && { required }),
            };

            if (shouldAddDefinition) {
                // fill in placeholder
                definitions[type.name as string] = addDescription(
                    comments,
                    type.node,
                    object,
                );
            }

            const schema = type.name && definitions[type.name]
                ? { $ref: `#/$defs/${type.name}` }
                : object;

            return addDescription(comments, type.node, schema);

        case 'any':
            return addDescription(comments, type.node, { type: 'any' });
        case 'none':
            return addDescription(comments, type.node, { type: 'null' });
        default:
            throw new Error(`Unsupported type: ${(type as any).kind}`);
    }
}

const mapPrimaryType = (typeKind: string): JSONSchema => {
    switch (typeKind) {
        case 'int':
        case 'float':
            return { type: 'number' };
        case 'bool':
            return { type: 'boolean' };
        case 'str':
            return { type: 'string' };
        case 'list':
            return { type: 'array', items: { type: 'any' } };
        case 'dict':
            return { type: 'object', additionalProperties: { type: 'any' } };
        case 'set':
            return { type: 'array', items: { type: 'any' }, uniqueItems: true };
        case 'tuple':
            return { type: 'array', items: { type: 'any' } };
        default:
            throw new Error(`Unsupported primary type: ${typeKind}`);
    }
}

type PyToken = [
    type: number,
    value: string,
    start: [number, number],
    end: [number, number],
    line: string,
];

const COMMENT = 61;

export const getComments = async (pyodide: PyodideInterface, code: string) => {
    let comments: PyToken[] = [];
    try {
        const locals = pyodide.globals.get("dict")();
        locals.set('code', code);
        const tokens = await pyodide.runPythonAsync(
            `from tokenize import tokenize, COMMENT
from io import BytesIO
result = []
tokens = tokenize(BytesIO(code.encode('utf-8')).readline)
[x for x in tokens]`,
            {
                filename: '__tokenizer__.py',
                locals,
            },
        );
        comments = (toJs(tokens) as PyToken[]).filter(token => token[0] === COMMENT);
    } catch (error) {
        console.warn('Failed to tokenize code', { error, code });
    }
    const lineToComments = new Map<number, PyToken[]>();
    for (const comment of comments) {
        const [start] = comment[3];
        const comments = lineToComments.get(start) || [];
        comments.push(comment);
        lineToComments.set(start, comments);
    }
    console.log('getComments', {
        code,
        comments,
        lineToComments,
    });
    return lineToComments;
};

const allowedOmniModuleNames = new Set(['omnince', 'omni']);

export const parseToolsFromAST = (ast: PyProxy, comments: Map<number, PyToken[]>) => {
    const items = toJs(ast.body) as PythonASTNode[];

    const typeAliases = new Map<string, Type>(
        items
            .map(item =>
                item.__type__ === 'ClassDef' ? [item.name, parseType(item)]
                    : item.__type__ === 'Assign' && item.targets.length === 1 ? [item.targets[0].id, parseType(item.value)]
                        : undefined
            )
            .filter((item): item is [string, any] => item?.[1])
    );

    const definitions: Record<string, JSONSchema> = {};
    const toolDefs: PythonToolDefinition[] = items
        .filter(item => item.__type__ === 'FunctionDef' || item.__type__ === 'AsyncFunctionDef')
        .filter(funcDef => funcDef.decorator_list.length > 0 && (funcDef.decorator_list as any[]).some(decorator => {
            return decorator.func.attr === 'tool'
                && allowedOmniModuleNames.has(decorator.func.value.id);
        }))
        .map(toolDef => {
            // rewrite the args as a top level object schema
            const schema = typeToJSONSchema(
                {
                    kind: 'object',
                    fields: toolDef.args.args.map((arg: PythonASTNode) => ({
                        name: arg.arg,
                        type: parseType(arg.annotation),
                        node: arg,
                    })),
                    node: toolDef.args,
                },
                typeAliases,
                definitions,
                comments,
            );
            // get the definitions that were referenced
            const findRefs = (schema: JSONSchema, refs = new Set<string>()) => {
                if (schema.$ref) {
                    const ref = schema.$ref.split('/').pop();
                    if (ref) {
                        refs.add(ref);
                    }
                } else if (schema.anyOf) {
                    schema.anyOf.forEach((item: JSONSchema) => findRefs(item, refs));
                } else if (schema.properties) {
                    (Object.values(schema.properties) as JSONSchema[]).forEach(item => findRefs(item, refs));
                }
                return refs;
            };
            const $defs = {} as Record<string, JSONSchema>;
            for (const ref of findRefs(schema)) {
                if (definitions[ref]) {
                    $defs[ref] = definitions[ref];
                }
            }
            return {
                name: toolDef.name,
                displayName: toolDef.decorator_list[0].keywords?.find((kwarg: PythonASTNode) => kwarg.arg === 'displayName')?.value?.s,
                description: toolDef.decorator_list[0].keywords?.find((kwarg: PythonASTNode) => kwarg.arg === 'description')?.value?.s,
                async: toolDef.__type__ === 'AsyncFunctionDef',
                args: toolDef.args.args.map((arg: PythonASTNode) => arg.arg),
                position: {
                    line: toolDef.decorator_list[0].lineno,
                    column: toolDef.decorator_list[0].col_offset,
                },
                schema: {
                    ...schema,
                    ...(Object.values($defs).length > 0
                        ? { $defs }
                        : {}
                    ),
                },
            } as PythonToolDefinition
        });

    return toolDefs;
}

const knownSafeCrossOrigin = [
    'https://cdn.jsdelivr.net',
    'https://pypi.org',
    'https://files.pythonhosted.org',
    // eslint-disable-next-line no-restricted-globals
    location.origin,
];

const rewriteUrl = (url: string) => {
    if (url && (knownSafeCrossOrigin.some(x => url.startsWith(x)) || url.includes('cdn'))) {
        return url;
    }
    return 'https://corsproxy.io/?' + encodeURIComponent(url);
};

class PyodideXMLHttpRequest extends XMLHttpRequest {
    open(...args: any[]) {
        console.log('pyodide.XMLHttpRequest.open.before', ...args);
        if (args[1]) {
            args[1] = typeof args[1] === 'string' ? rewriteUrl(args[1])
                : args[1] instanceof URL ? new URL(rewriteUrl(args[1].href), args[1])
                    : args[1];
        }
        console.log('pyodide.XMLHttpRequest.open.after', ...args);
        return super.open(...args as Parameters<typeof XMLHttpRequest['prototype']['open']>);
    }
}

const fakeWindow = {};

export const createPyodideInstance = () => {
    return loadPyodide({
        jsglobals: {
            setTimeout: (...args: Parameters<typeof setTimeout>) => setTimeout(...args),

            // support for urllib3
            // see https://github.com/urllib3/urllib3/blob/main/src/urllib3/contrib/emscripten/fetch.py for references
            Object: {
                fromEntries: (...args: Parameters<typeof Object.fromEntries>) => {
                    return Object.fromEntries(...args);
                },
            },
            fetch: (...args: Parameters<typeof fetch>) => {
                console.log('pyodide.fetch.before', ...args);
                args[0] = typeof args[0] === 'string'
                    ? rewriteUrl(args[0])
                    : args[0] instanceof Request ? new Request(rewriteUrl(args[0].url), args[0])
                        : args[0] instanceof URL ? new URL(rewriteUrl(args[0].href), args[0])
                            : args[0];
                console.log('pyodide.fetch.after', ...args);
                return fetch(...args);
            },
            console,
            XMLHttpRequest: PyodideXMLHttpRequest,
            URL,
            // urllib3 checks equality of `self` and `window` to determine if it's running in a browser
            // see `is_in_browser_main_thread` in https://github.com/urllib3/urllib3/blob/main/src/urllib3/contrib/emscripten/fetch.py#L292-L293
            self: fakeWindow,
            window: fakeWindow,
            TextDecoder,
            TextEncoder,
            Atomics,
            SharedArrayBuffer: globalThis.SharedArrayBuffer,
            Int32Array,
            Uint8Array,
            location: {
                // eslint-disable-next-line no-restricted-globals
                href: location.href,
            },
            globalThis: {
                Promise,
                Worker,
            },
            Blob,
        },
    });
};

interface InstallPackagesResult {
    success: boolean;
    loadedPackages: string[];
    errorMessages?: string[];
}

export const installPackages = async (pyodide: PyodideInterface, code: string): Promise<InstallPackagesResult> => {
    console.groupCollapsed('installPackages');
    console.log('code', { code });
    const loadedPackageNames = new Set<string>();
    const errorMessages = [] as string[];

    console.group('trying to optimistically load packages from imports...');
    try {
        const loadedPackages = await pyodide.loadPackagesFromImports(code, {
            messageCallback: message => console.log('loadPackagesFromImports (optimistic)', message),
            errorCallback: error => console.warn('loadPackagesFromImports (optimistic)', error),
        });
        console.log('loadPackagesFromImports.result', loadedPackages);
        for (const loadedPackage of loadedPackages) {
            loadedPackageNames.add(loadedPackage.name);
        }
    } catch (error) {
        console.warn('Failed to load packages from imports', error);
    }
    console.groupEnd();

    console.group('loading micropip');
    let micropip: PyProxy | undefined = undefined;
    try {
        await pyodide.loadPackage('micropip', {
            messageCallback: x => console.log('loadPackage.micropip', x),
            errorCallback: x => console.error('loadPackage.micropip', x),
        });
        micropip = pyodide.pyimport('micropip');
    } catch (error) {
        console.error('Failed to load micropip', error);
        errorMessages.push('Failed to load micropip');
        if (error instanceof Error) {
            errorMessages.push(`${error.name} ${error.message}`);
        }
    }
    console.groupEnd();

    if (micropip === undefined) {
        return {
            success: false,
            loadedPackages: [],
            errorMessages,
        };
    }

    console.group('finding imports');
    let packageNames: string[] | undefined = undefined;
    try {
        packageNames = toJs(pyodide.pyodide_py.code.find_imports(code));
        console.log('found imports', packageNames);
    } catch (error) {
        console.error('Failed to find imports from code', error, { code });
        errorMessages.push('Failed to find imports from code');
        if (error instanceof Error) {
            errorMessages.push(`${error.name} ${error.message}`);
        }
    }
    console.groupEnd();

    if (packageNames === undefined) {
        return {
            success: false,
            loadedPackages: [],
            errorMessages,
        };
    }

    console.group('installing packages');
    console.log('package names', packageNames);
    let success = true;
    for (const packageName of packageNames) {
        if (packageName in pyodide.loadedPackages || loadedPackageNames.has(packageName)) {
            console.log(`skipping package: ${packageName} as it's already loaded`);
            loadedPackageNames.add(packageName);
            continue;
        } else {
            let loaded = false;
            try {
                console.log(`checking if package: ${packageName} is already loaded or loadable`);
                loaded = pyodide.pyimport(packageName) != null
            } catch (error) {
                console.warn(`Failed to check if package: ${packageName} is already loaded or loadable`, error);
            }
            if (loaded) {
                loadedPackageNames.add(packageName);
                console.log(`skipping package ${packageName} as it's already loaded`);
                continue;
            } else {
                const micropipInstall = async () => {
                    console.group('micropip.install package:', packageName);
                    try {
                        await micropip!.install(packageName);
                        const installResult = pyodide.pyimport(packageName);
                        console.groupEnd();
                        return installResult;
                    } catch (error) {
                        console.error(`Failed to micropip.install package: ${packageName}`, error);
                        errorMessages.push(`Failed to micropip.install package: ${packageName}`);
                        if (error instanceof Error) {
                            errorMessages.push(`${error.name} ${error.message}`);
                        }
                    }
                    console.groupEnd();
                };

                console.group(`package ${packageName} not founded, installing...`);
                let optimisticallyLoaded = false;
                try {
                    console.log(`trying optimistic package load for ${packageName}`);
                    const packageLoad = await pyodide.loadPackage(packageName, {
                        messageCallback: x => console.log('loadPackage.optimistic', x),
                        errorCallback: x => console.warn('loadPackage.optimistic', x),
                    });
                    console.log('optimistic package load result', packageLoad);
                    optimisticallyLoaded = packageLoad?.[0].name === packageName;
                } catch (error) {
                    console.warn(`Failed to optimistically load package: ${packageName}`, error);
                }
                if (optimisticallyLoaded) {
                    console.log(`optimistically loaded ${packageName}`);
                    loadedPackageNames.add(packageName);
                } else {
                    const micropipInstallResult = await micropipInstall();
                    if (micropipInstallResult === undefined) {
                        success = false;
                        console.error(`Failed to resolve micropip.install package: ${packageName}.`);
                        errorMessages.push(`Failed to resolve micropip.install package: ${packageName}`);
                        continue;
                    } else {
                        loadedPackageNames.add(packageName);
                    }
                }
                console.groupEnd();
            }
        }
    }
    console.groupEnd();

    try {
        if ('urllib3' in pyodide.loadedPackages) {
            await pyodide.runPythonAsync(`import urllib3; urllib3.disable_warnings(urllib3.exceptions.InsecureRequestWarning)`);
        }
    } catch (error) {
        console.warn('Failed to disable urllib3 warnings', error);
    }

    console.groupEnd();

    return {
        success,
        loadedPackages: Array.from(loadedPackageNames),
        errorMessages,
    };
};

interface Doc {
    id: string;
    entity: any;
}

const omniStorage = entityDb<Doc>({
    name: 'OmniStorage',
});

const createStorageModule = (prefix: string) => ({
    get: (id: string) => omniStorage.get(`${prefix}/${id}`).then(doc => doc?.entity),
    get_all: () => omniStorage.getAll().then(docs =>
        docs.filter(doc => doc.id.startsWith(prefix)).map(doc => doc.entity),
    ),
    set: (id: string, entity: any) => omniStorage.put(`${prefix}/${id}`, {
        id: `${prefix}/${id}`,
        entity: toJs(entity),
    }),
    remove: (id: string) => omniStorage.remove(`${prefix}/${id}`),
});

interface ToolDecoratorKwArgs {
    displayName?: string;
    description?: string;
    hidden?: boolean;
    predicate?: ToolRef['predicate'];
}

type ModifyMessagesHook = ((messages: any[]) => any[] & PyProxy) & PyProxy;

interface EnvHooks {
    modifyMessage?: ModifyMessagesHook;
}

export const createOmninceEnvironment = async (
    instanceId: string,
    agentId: string,
    pyodide?: PyodideInterface,
    getModules?: () => Record<string, any>,
) => {

    if (!pyodide) {
        pyodide = await createPyodideInstance();
    }

    if (process.env.NODE_ENV === 'development') {
        pyodide.setDebug(true);
    }

    const create_proxy = pyodide.pyodide_py.ffi.create_proxy;

    const toolRefs = new Map<string, ToolRef>();
    const tool = (kwargs: ToolDecoratorKwArgs & PyProxy = {} as PyProxy) => {
        kwargs = toJs(kwargs);
        const predicate = kwargs.predicate && create_proxy(kwargs.predicate);
        return (instance: ToolRef['instance']) => {
            instance = create_proxy(instance);
            const name = instance.__name__;
            console.log('omnince.tool.register', name, kwargs);
            toolRefs.set(name, {
                name,
                displayName: kwargs.displayName,
                description: kwargs.description,
                hidden: kwargs.hidden,
                predicate,
                instance,
                definition: undefined,
            });
            return instance;
        };
    };

    const hooks = {
        modifyMessage: undefined,
    } as EnvHooks;

    const modifyMessageHookDecorator = () => {
        return (hook: ModifyMessagesHook) => {
            hook = create_proxy(hook);
            hooks.modifyMessage = hook;
            return hook;
        };
    };

    const baseOmninceModule = {
        tool,
        modify_messages: modifyMessageHookDecorator,
        storage: {
            shared: createStorageModule('shared'),
            assistant: createStorageModule(`assistant/${agentId}`),
            chat: createStorageModule(`chat/${instanceId}`),
        }
    };

    const omninceModule = new Proxy({}, {
        get: (_, prop) => {
            if (prop in baseOmninceModule) {
                return baseOmninceModule[prop as keyof typeof baseOmninceModule];
            }
            const modules = getModules ? getModules() : {};
            if (prop in modules) {
                return modules[prop as string];
            }
            return undefined;
        },
    });

    pyodide.registerJsModule('omnince', omninceModule);

    return {
        pyodide,
        toolRefs,
        hooks,
    };
};

export const getGlobals = (pyodide: PyodideInterface) => {
    const omnince = pyodide.pyimport('omnince');

    if (process.env.NODE_ENV === 'development') {
        pyodide.setStderr({ batched: console.error });
        pyodide.setStdout({ batched: console.log });
    }

    const dict = pyodide.globals.get("dict");
    const globals = dict();
    globals.set('omnince', omnince);
    globals.set('omni', omnince);

    return globals;
}
