import {
    createAsyncThunk,
    createSlice,
    createEntityAdapter,
    type EntityState,
    type PayloadAction,
    createSelector,
} from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import type { Model, ModelsPage } from 'openai/resources';

import type { RootState } from '../../app/store';
import { deleteAgent as deleteAgentData, getAgent, getAllAgents, putAgent } from './Agent.data';
import type { Entity } from '../../contracts/entity';
import type { Agent, AgentDao } from '../../contracts/agent';
import type { OpenAI } from '../../modules/openai';
import { logEvent } from '../../app/firebase';
import { getBuiltAgents } from './builtin';

type AgentEntity = Entity<Agent>;

const agentAdapter = createEntityAdapter<AgentEntity>();
const modelAdapter = createEntityAdapter<Model>();

export interface AgentState {
    agents: EntityState<AgentEntity>;
    models: EntityState<Model>;
}

export const loadAgents = createAsyncThunk('agents/loadAgents', async () => {
    return [
        ...await getAllAgents(),
        ...getBuiltAgents(),
    ];
});

export const loadAgent = createAsyncThunk('agents/loadAgent', async (id: string, { getState, rejectWithValue }) => {
    const existingAgent = selectAgentById(getState() as RootState, id);
    if (existingAgent?.status === 'fulfilled' && existingAgent?.entity) {
        return existingAgent.entity;
    }
    const agent = await getAgent(id);
    if (agent) {
        return agent;
    } else {
        return rejectWithValue(undefined);
    }
});

export const updateAgent = createAsyncThunk('agents/updateAgent', (agent: Agent) => {
    if (agent.transient) {
        return;
    }
    return putAgent(agent.id, agent);
});

export const deleteAgent = createAsyncThunk('agents/deleteAgent', (id: string) => {
    return deleteAgentData(id);
});

export const createAgent = createAsyncThunk('agents/createAgent', async (agent?: Partial<Agent>) => {
    logEvent('createAgent');
    const id = uuid();
    const createdAt = Date.now();
    const agentDao: AgentDao = {
        name: 'New assistant',
        description: '',
        model: 'gpt-3.5-turbo-0125',
        createdAt,
        lastUsedAt: createdAt,
        temperature: 0.25,
        instructions: '',
        toolContexts: {},
        tools: [],
        ...agent,
        id,
    };
    await updateAgent(agentDao);
    return agentDao;
});

export const loadModels = (openAI: OpenAI) => createAsyncThunk('agents/loadModels', async (_, { getState, dispatch }) => {
    if ((getState() as RootState).agents.models.ids.length > 0) {
        return;
    }
    const models = await openAI.models.list();
    for await (const page of models.iterPages()) {
        dispatch(agentsSlice.actions.appendModels((page as ModelsPage).data));
    }
});

const initialState: AgentState = {
    agents: agentAdapter.getInitialState(),
    models: modelAdapter.getInitialState(),
};

export const agentsSlice = createSlice({
    name: 'agents',
    initialState,
    reducers: {
        appendModels: (state, action: PayloadAction<Model[]>) => {
            modelAdapter.addMany(state.models, action.payload);
        },
    },
    extraReducers(builder) {
        builder
            .addCase(loadAgent.pending, (state, action) => {
                if (!state.agents.entities[action.meta.arg]) {
                    agentAdapter.addOne(state.agents, {
                        status: 'pending',
                        id: action.meta.arg,
                        entity: undefined,
                    });
                }
            })
            .addCase(loadAgent.fulfilled, (state, action) => {
                if (action.payload && state.agents.entities[action.meta.arg]?.status === 'pending') {
                    agentAdapter.upsertOne(state.agents, {
                        status: 'fulfilled',
                        id: action.meta.arg,
                        entity: action.payload,
                    });
                }
            })
            .addCase(loadAgent.rejected, (state, action) => {
                if (state.agents.entities[action.meta.arg]?.status === 'pending') {
                    agentAdapter.upsertOne(state.agents, {
                        status: 'rejected',
                        id: action.meta.arg,
                        entity: undefined,
                        error: {
                            message: '',
                            code: '',
                        },
                    });
                }
            });

        builder
            .addCase(updateAgent.pending, (state, action) => {
                agentAdapter.upsertOne(state.agents, {
                    status: 'fulfilled',
                    id: action.meta.arg.id,
                    entity: action.meta.arg,
                });
            });

        builder
            .addCase(deleteAgent.fulfilled, (state, action) => {
                agentAdapter.removeOne(state.agents, action.meta.arg);
            });

        builder
            .addCase(createAgent.fulfilled, (state, action) => {
                agentAdapter.addOne(state.agents, {
                    status: 'fulfilled',
                    id: action.payload.id,
                    entity: action.payload,
                });
            });

        builder
            .addCase(loadAgents.fulfilled, (state, action) => {
                agentAdapter.setAll(state.agents, action.payload.map(entity => ({
                    status: 'fulfilled',
                    id: entity.id,
                    entity,
                })));
            });
    },
});

export const selectAgentsState = (state: RootState) => state.agents;

export const {
    selectAll: selectAllAgents,
    selectById: selectAgentById,
} = agentAdapter.getSelectors((state: RootState) => selectAgentsState(state).agents);

export const makeSelectAgent = (id: string) => createSelector(
    (state: RootState) => selectAgentById(state, id),
    agent => agent,
);

export const makeSelectAllAgents = () => createSelector(
    selectAllAgents,
    agents => agents
        .filter(agent => !!agent.entity && !agent.entity.transient)
        .toSorted((a, b) => (b.entity!.lastUsedAt || 0) - (a.entity!.lastUsedAt || 0)),
);

const modelSelectors = modelAdapter.getSelectors((state: RootState) => selectAgentsState(state).models);

export const selectAllModels = createSelector(
    modelSelectors.selectAll,
    models => models
        .filter(model => model.id.startsWith('gpt'))
        .toSorted((a, b) => b.created - a.created),
);

export default agentsSlice.reducer;
