import type { ChatDao } from "../../contracts/chat";
import { entityDb } from "../../helpers/entityDb";

export const {
    get: getChat,
    getAll: getAllChats,
    put: updateChat,
    remove: deleteChat,
} = entityDb<ChatDao>({
    name: 'ChatInstances',
});