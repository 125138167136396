import { memo, type ReactNode } from 'react';
import type { MenuProps } from 'antd';
import { Layout, Menu, theme } from 'antd';
import { gray } from '@ant-design/colors';

import styles from './Shell.module.css';

const { Content, Sider } = Layout;

export interface ShellProps {
    sideNav: ShellMenu;
    sideNavBottom: ShellMenu;
    children?: ReactNode;
}

export interface ShellMenu extends MenuProps {
}

const Shell = memo((props: ShellProps) => {
    const {
        token: { colorText },
    } = theme.useToken();
    return (
        <Layout>
            <Layout hasSider>
                <Sider className={styles.sideNav}>
                    <Menu
                        mode='vertical'
                        className={styles.sideNavTop}
                        {...props.sideNav}
                    />
                    <Menu
                        mode='vertical'
                        className={styles.sideNavBottom}
                        {...props.sideNavBottom}
                    />
                </Sider>
                <Content style={{
                    height: '100vh',
                    background: gray[7],
                    color: colorText,
                }}>
                    {props.children}
                </Content>
            </Layout>
        </Layout>
    );
});

export default Shell;