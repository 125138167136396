
export class LazyPromise<T> implements Promise<T> {
    
    private promise: Promise<T> | undefined;
    readonly [Symbol.toStringTag] = 'LazyPromise';
    private readonly executor: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void

    constructor(
        executor: () => T | PromiseLike<T>,
    ) {
        this.executor = (resolve, reject) => {
            try {
                const result = executor();
                if (result instanceof Promise) {
                    result.then(resolve, reject);
                } else {
                    resolve(result);
                }
            } catch (error) {
                reject(error);
            }
        };
    }

    private init() {
        if (!this.promise) {
            this.promise = new Promise<T>(this.executor);
        }
    }

    public then<TResult1 = T, TResult2 = never>(
        onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null,
        onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null,
    ): Promise<TResult1 | TResult2> {
        this.init();
        return this.promise!.then(onfulfilled, onrejected);
    }
    
    public catch<TResult = never>(
        onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null,
    ): Promise<T | TResult> {
        this.init();
        return this.promise!.catch(onrejected);
    }

    public finally(onfinally?: (() => void) | undefined | null): Promise<T> {
        this.init();
        return this.promise!.finally(onfinally);
    }
}