import { useEffect } from 'react';
import {
    Form,
    Input,
    Skeleton,
    Typography,
} from 'antd';

import styles from './Settings.module.css'
import { loadSettings, selectSettingsState } from './Settings.slice'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { updateSettings } from './Settings.slice';

const { Link } = Typography;

const SettingsPage = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(loadSettings());
    });
    const [form] = Form.useForm();
    const settingsEntity = useAppSelector(selectSettingsState)?.settings;
    const settings = settingsEntity?.status === 'idle' ? settingsEntity.entity : undefined;
    return <div className={styles.settingsPage}>
        {settings
            ? <Form
                name="settings"
                form={form}
                layout="vertical"
                className={styles.settingsForm}
                onValuesChange={changedValues => dispatch(updateSettings(changedValues))}
                initialValues={settings}
            >
                <Form.Item
                    name="openaiApiKey"
                    label="OpenAI API Key"
                    required
                    tooltip={<div>
                        <div>An OpenAI platform <Link href='https://platform.openai.com/api-keys' target='_blank'>API key</Link>.</div>
                        <div>It's suggested to create a scoped and monitored key specifically for this application.</div>
                        <div>This key is used to operate chat completions and DALL-E image generation.</div>
                        <div>It is only stored client side on your browser and it's never sent anywhere other than OpenAI.</div>
                        <div>All model defaults are gpt-3.5-turbo to avoid unintended expense, with the exception of DALL-E 3.</div>
                    </div>}
                >
                    <Input placeholder="sk-a1b2c3d4..." />
                </Form.Item>
            </Form>
            : <Skeleton active paragraph={{ rows: 4 }} />
        }
    </div>
};

export default SettingsPage;
