import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent as _logEvent } from 'firebase/analytics';

const app = initializeApp({
    apiKey: 'AIzaSyCFiQIkD9pyCA23j5UKZOQGSFCy4cS1Rzw',
    authDomain: 'omnince.firebaseapp.com',
    projectId: 'omnince',
    storageBucket: 'omnince.appspot.com',
    messagingSenderId: '1013857995526',
    appId: '1:1013857995526:web:9881eeb43ef471c1ab2c87',
    measurementId: 'G-QRMLRMGEZQ',
});

const analytics = getAnalytics(app);

export const logEvent = (name: string, params?: Record<string, any>) => {
    _logEvent(analytics, name, params);
};