export const examples = [
    {
        key: 'arxiv_search',
        label: 'arXiv Search',
    },
    {
        key: 'coin_toss',
        label: 'Coin Toss',
    },
    {
        key: 'convert_currency',
        label: 'Convert Currency',
    },
    {
        key: 'documentation',
        label: 'Documentation',
    },
    {
        key: 'kitchen_sink',
        label: 'Kitchen Sink (all)',
    },
    {
        key: 'memory',
        label: 'Memory',
    },
    {
        key: 'python_eval',
        label: 'Python',
    },
    {
        key: 'random_number',
        label: 'Random Number',
    },
    {
        key: 'roll_dice',
        label: 'Roll Dice',
    },
    {
        key: 'weather',
        label: 'Weather',
    },
    {
        key: 'youtube',
        label: 'YouTube',
    },
] as const;

export type Example = typeof examples[number]['key'];

// eslint-disable-next-line import/no-webpack-loader-syntax
export const getExample = (key: Example) => import(`!!raw-loader!./${key}.py`).then(module => module.default as string);
