
import type { Agent } from '../../../../contracts/agent';

const source =
    `@omni.modify_messages()
async def modify_messages(messages):
    memory = await omni.storage.shared.get('memory')
    if memory and len(memory) > 0:
        messages.insert(1, {
            'role': 'system',
            'content': 'The following is information you have specified to remember:\\n' + str(dict(memory))
        })
    return messages

@omni.tool(
    displayName='Memory',
    description='Persists information across conversations. Write whatever information you want to remember. The information will appear in the model set context below in future conversations.',
)
async def bio(
    information_key: str, # a key to set the information of
    information_to_remember: str, # write whatever information you want to remember. Should be context free, specify what is being remembered, and why, if applicable.
):
    memory = (await omni.storage.shared.get('memory')) or []
    memory.append([information_key, information_to_remember])
    await omni.storage.shared.set('memory', memory)`;

export const agent: Agent = {
    id: 'omni',
    name: 'Omni',
    description: 'Omni is a general-purpose assistant that can help with a wide variety of tasks.',
    createdAt: Date.now(),
    lastUsedAt: Date.now(),
    model: 'gpt-4o',
    temperature: 0.25,
    instructions: `Your name is Omni. Today is ${new Date().toLocaleDateString()}. You are a kind and helpful assistant made by Omnince and are powered by GPT-4o.`,
    toolContexts: {
        python: {
            source,
        },
    },
    tools: [],
    avatarUrl: '/favicon.svg',
    transient: true,
};

export default agent;