import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import type { AppSettings } from '../../contracts/settings';
import { getSettings, updateSettings as dataUpdateSettings } from './Settings.data';

type Entity<T> = {
    status: 'idle';
    id: string;
    entity: T;
} | {
    status: 'loading' | 'failed';
    id: string;
    entity?: undefined;
};

export interface SettingsState {
    settings: Entity<AppSettings>;
}

export const loadSettings = createAsyncThunk('settings/load', () => {
    return getSettings();
});

export const updateSettings = createAsyncThunk('settings/save', async (settings: Partial<AppSettings>, { getState }) => {
    const settingsState = selectSettingsState(getState() as RootState).settings;
    const currentSettings = settingsState.status === 'idle' ? settingsState.entity : {};
    const newSettings = {
        ...currentSettings,
        ...settings as AppSettings,
    };
    await dataUpdateSettings(newSettings);
    return newSettings;
});

const initialState: SettingsState = {
    settings: {
        status: 'loading',
        id: 'app',
    },
};

export const settingsSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder
            .addCase(loadSettings.fulfilled, (state, action) => {
                if (action.payload && state.settings.status === 'loading') {
                    state.settings = {
                        status: 'idle',
                        id: 'app',
                        entity: action.payload,
                    };
                }
            });

        builder
            .addCase(updateSettings.fulfilled, (state, action) => {
                if (action.payload) {
                    state.settings = {
                        status: 'idle',
                        id: 'app',
                        entity: action.payload,
                    };
                }
            });
    },
});

export const selectSettingsState = (state: RootState) => state.settings;
export const selectSettings = (state: RootState) => state.settings.settings.entity;

export const selectHasAPIKey = (state: RootState) => (selectSettings(state)?.openaiApiKey?.length ?? 0) > 0;

export default settingsSlice.reducer;
