import type { AgentDao } from "../../contracts/agent";
import { entityDb } from "../../helpers/entityDb";

export const {
    get: getAgent,
    getAll: getAllAgents,
    put: putAgent,
    remove: deleteAgent,
} = entityDb<AgentDao>({
    name: 'Agents',
});