import { LazyPromise } from "./lazyPromise";
import { PersistentMemoryDB } from "./persistentMemoryDB";

export interface EntityDBOptions {
    name: string;
    lazy?: boolean;
}

export const entityDb = <T extends { id: string }>(options: EntityDBOptions) => {
    const lazyDb = (options.lazy || options.lazy === undefined)
        ? new LazyPromise(async () => {
            const db = new PersistentMemoryDB(options.name);
            await db.isReady();
            return db;
        })
        : new Promise<PersistentMemoryDB>(async (resolve, reject) => {
            try {
                const db = new PersistentMemoryDB(options.name);
                await db.isReady();
                resolve(db);
            } catch (error) {
                reject(error);
            }
        });

    const getAll = async () => {
        const db = await lazyDb;
        return db.getAll<T>();
    };

    const get = async (id: string) => {
        const db = await lazyDb;
        return db.get<T>(id);
    };

    const put = async (id: string, entity: T) => {
        const db = await lazyDb;
        return db.set(id, entity);
    }

    const remove = async (id: string) => {
        const db = await lazyDb;
        return db.delete(id);
    };

    return {
        getAll,
        get,
        put,
        remove,
    };
};