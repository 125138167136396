import OriginalOpenAI from 'openai';
import { useEffect, type ReactNode, createContext, useContext } from 'react';

import { useAppSelector } from '../app/hooks';
import { selectSettings } from '../components/settings/Settings.slice';

export class OpenAI extends OriginalOpenAI {
    activeAbortController?: AbortController;
}

const openAI = new OpenAI({
    apiKey: '',
    dangerouslyAllowBrowser: true,
});

export const OpenAIContext = createContext<OpenAI>(openAI);

interface OpenAIProviderProps {
    children: ReactNode;
}

export const OpenAIProvider = ({ children }: OpenAIProviderProps) => {
    const settings = useAppSelector(selectSettings);

    useEffect(() => {
        if (settings?.openaiApiKey) {
            openAI.apiKey = settings.openaiApiKey;
        }
    }, [settings]);

    return (
        <OpenAIContext.Provider value={openAI}>
            {children}
        </OpenAIContext.Provider>
    );
};

export const useOpenAI = () => useContext(OpenAIContext);

export default useOpenAI;