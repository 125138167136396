import { KeyboardEvent, memo, useCallback, useEffect, useRef, useState } from 'react';
import { Avatar, Button, Collapse, Input, Skeleton, Typography, Tooltip, InputRef, Spin, Divider, Space, Flex } from 'antd';
import { SendOutlined, UserOutlined, RobotOutlined, CopyOutlined, CheckCircleFilled, EditOutlined, CheckOutlined, CloseOutlined, LeftOutlined, RightOutlined, ReloadOutlined, StopOutlined, CloseCircleOutlined, FileImageOutlined } from '@ant-design/icons';
import Markdown, { Components } from 'react-markdown';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SyntaxHighlighter from 'react-syntax-highlighter';
import 'highlight.js/styles/atom-one-dark.css';
import { v4 as uuid } from 'uuid';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import remarkGfm from 'remark-gfm';
import { green } from '@ant-design/colors';

import type { Chat, Message, MessagePartMarkdown, MessagePartTool } from '../../contracts/chat';
import styles from './Chat.module.css'
import { useAppDispatch, useAppSelector, useMemoSelector } from '../../app/hooks';
import { UserImage, createChat, makeSelectActiveMessageBranch, makeSelectChat, makeSelectChatStatus, makeSelectMessagePartById, regenerateAgentMessage, selectChatById, selectMessagePartById, sendUserMessage, switchMessage } from './Chat.slice';
import useOpenAI from '../../modules/openai';
import usePython from '../../modules/python';
import { makeSelectAgent } from '../agent/Agent.slice';
import { selectHasAPIKey } from '../settings/Settings.slice';
import { ImageInput } from '../image-input/ImageInput';

const { Text, Title } = Typography;

const { TextArea } = Input;

const CopyCodeButton = memo((props: { code: string }) => {
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    return <CopyToClipboard
        text={props.code}
        onCopy={() => {
            setTooltipOpen(true);
            setTimeout(() => {
                setTooltipOpen(false);
            }, 1000);
        }}
    >
        <Tooltip
            open={tooltipOpen}
            title={<>
                <CheckCircleFilled style={{ color: green.primary }} />
                <Text style={{ marginLeft: 8 }}>Copied</Text>
            </>}
            placement='left'
        >
            <Button
                style={{
                    position: 'absolute',
                    bottom: 4,
                    right: 4,
                    background: 'transparent',
                }}
                icon={<CopyOutlined />}
                size='small'
            />
        </Tooltip>
    </CopyToClipboard>;
});

interface CodeProps {
    language: string;
    code: string;
}

const customCodeStyle = {
    margin: 0,
    borderRadius: 0,
    fontSize: 12,
    padding: '6px 36px 6px 12px', // 36px for copy button
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    boxSizing: 'border-box',
} as const;

const collapseNoPaddingStyle = {
    padding: 0,
    overflow: 'hidden',
};

const Code = memo((props: CodeProps) => {
    const code = String(props.code).replace(/\n$/, '');
    return (
        <Collapse
            size='small'
            defaultActiveKey='code'
            expandIconPosition='end'
            items={[{
                key: 'code',
                label: props.language,
                style: collapseNoPaddingStyle,
                children:
                    <div className={styles.collapseContent}>
                        <CopyCodeButton code={code} />
                        <SyntaxHighlighter
                            useInlineStyles={false}
                            language={props.language}
                            PreTag='div'
                            customStyle={customCodeStyle}
                        >
                            {code}
                        </SyntaxHighlighter>
                    </div>
            }]}
        />
    );
});

const markdownComponents: Partial<Components> = {
    code(props) {
        const { children, className, node, ...rest } = props;
        const match = /language-(\w+)/.exec(className || '');
        return match
            ? <Code
                language={match[1]}
                code={String(children)}
            />
            : <code {...rest} className={className}>
                {children}
            </code>;
    },
};

interface MessagePartMarkdownProps {
    part: MessagePartMarkdown;
}

const remarkPlugins = [remarkGfm];
const MessagePartMarkdownComponent = memo((props: MessagePartMarkdownProps) => (
    <Markdown
        components={markdownComponents}
        remarkPlugins={remarkPlugins}
    >
        {props.part.text}
    </Markdown>
));

const tryFormatJSON = (json: string) => {
    try {
        return JSON.stringify(JSON.parse(json), null, 2);
    } catch {
        return json;
    }
};

interface MessagePartToolProps {
    part: MessagePartTool;
}

const MessagePartToolComponent = memo((props: MessagePartToolProps) => (
    <Collapse
        size='small'
        expandIconPosition='end'
        items={[
            {
                key: 'tool_call',
                label: <div className={styles.toolCallLabel}>
                    {props.part.toolDisplayName || props.part.toolName}
                    {props.part.error && <Button
                        type='link'
                        danger
                        size='small'
                        className={styles.toolCallErrorButton}>
                        <CloseCircleOutlined />
                    </Button>}
                    {props.part.status === 'in_progress' && <Spin size='small' />}
                </div>,
                children:
                    <div className={styles.collapseContent}>
                        {props.part.toolCallArgs && <SyntaxHighlighter
                            useInlineStyles={false}
                            language='json'
                            PreTag='div'
                            customStyle={customCodeStyle}
                        >
                            {props.part.status === 'success'
                                ? tryFormatJSON(props.part.toolCallArgs)
                                : props.part.toolCallArgs || ''
                            }
                        </SyntaxHighlighter>}
                        {props.part.result && !props.part.error && <>
                            <Divider style={{ margin: 0 }} />
                            <SyntaxHighlighter
                                useInlineStyles={false}
                                language='json'
                                PreTag='div'
                                customStyle={customCodeStyle}
                            >
                                {props.part.result}
                            </SyntaxHighlighter>
                        </>}
                        {props.part.error && <>
                            <Divider style={{ margin: 0 }} />
                            <SyntaxHighlighter
                                useInlineStyles={false}
                                language='json'
                                PreTag='div'
                                customStyle={customCodeStyle}
                            >
                                {props.part.error}
                            </SyntaxHighlighter>
                        </>}
                    </div>
            },
        ]}
    />
));

interface MessagePartImage {
    url: string;
    name: string;
}

const MessagePartImageComponent = memo((props: { part: MessagePartImage }) => {
    return <img
        className={styles.chatImage}
        src={props.part.url}
        alt={props.part.name}
    />;
});

const MessagePart = memo((props: { id: string }) => {
    const part = useMemoSelector(() => makeSelectMessagePartById(props.id), [props.id]);
    if (!part) {
        return <div>
            Missing message part: {props.id}
        </div>;
    }
    return <div className={part.kind === 'tool' ? styles.messagePartTool
        : part.kind === 'markdown' ? styles.messagePartMarkdown
            : styles.messagePartImage}>
        {
            part.kind === 'markdown' ? <MessagePartMarkdownComponent part={part} />
                : part.kind === 'tool' ? (part.hidden ? <></> : <MessagePartToolComponent part={part} />)
                    : part.kind === 'image' ? <MessagePartImageComponent part={part} />
                        : <></>
        }
    </div>
});

const MessageContent = memo((props: { parts: string[] }) => {
    return <div className={styles.messageContent}>
        {props.parts.map(partId =>
            <MessagePart key={partId} id={partId} />
        )}
    </div>;
});

interface EditMessageProps {
    message: Message;
    onChange: (text: string) => void;
}

const EditMessage = memo((props: EditMessageProps) => {
    const [textAreaValue, setTextAreaValue] = useState(useAppSelector(state => {
        const part = selectMessagePartById(state, props.message.parts[props.message.parts.length - 1]); // only support editing text for now. when n>1, parts[n-1] is the text part
        return part?.kind === 'markdown' ? part.text : '';
    }));
    const inputRef = useRef<InputRef>(null);
    useEffect(() => inputRef.current?.focus({ cursor: 'end' }), []);
    return <div className={styles.editMessage}>
        <TextArea
            autoSize={true}
            ref={inputRef}
            styles={{
                textarea: {
                    border: 'none',
                    background: 'transparent',
                    outline: 0,
                    boxShadow: 'none',
                },
            }}
            value={textAreaValue}
            onChange={event => {
                setTextAreaValue(event.target.value);
                props.onChange(event.target.value);
            }}
        />
    </div>
});

interface UserMessage {
    event:
    | React.MouseEvent<HTMLElement, MouseEvent>
    | React.KeyboardEvent<HTMLTextAreaElement>;
    text: string;
    images?: UserImage[];
}

interface MessageHeaderProps {
    chatId: string;
    authorName: string;
    messageId: string;
    messageAuthorKind: Message['author']['kind'];
    avatarUrl?: string;
    activeIndex: number;
    siblingCount: number;
    isLoading?: boolean;
    isEditing: boolean;
    setEditingMessageId: (id?: string) => void;
    isHovered: boolean;
    onSubmitEdit: () => void;
}

const MessageHeader = memo((props: MessageHeaderProps) => {
    const dispatch = useAppDispatch();
    const openAI = useOpenAI();
    const python = usePython();
    return <div className={styles.messageHeader}>
        <div className={styles.messageHeaderItems}>
            {props.avatarUrl
                ? <Avatar
                    size={32}
                    src={props.avatarUrl}
                />
                : <Avatar
                    size={32}
                    icon={props.messageAuthorKind === 'user' ? <UserOutlined /> : <RobotOutlined />}
                />
            }
            <Text strong>
                {props.authorName}
            </Text>
            {props.messageAuthorKind === 'agent' && props.isHovered && !props.isEditing && <Button
                icon={<ReloadOutlined />}
                size='small'
                type='text'
                onClick={() => dispatch(regenerateAgentMessage(openAI, python)({
                    chatId: props.chatId,
                    messageId: props.messageId,
                }))}
            />}
            {props.messageAuthorKind === 'user' && props.isHovered && !props.isEditing && <Button
                icon={<EditOutlined />}
                size='small'
                type='text'
                onClick={() => props.setEditingMessageId(props.messageId)}
            />}
            {props.isEditing && <>
                <Button
                    icon={<CheckOutlined />}
                    size='small'
                    type='text'
                    onClick={() => props.onSubmitEdit()}
                />
                <Button
                    icon={<CloseOutlined />}
                    size='small'
                    type='text'
                    danger={true}
                    onClick={() => props.setEditingMessageId(undefined)}
                />
            </>}
            {props.isLoading && <Spin size='small' />}
        </div>
        <div className={styles.messageHeaderItems}>
            {props.siblingCount > 1 && props.isHovered &&
                <div className={styles.messageFooter}>
                    <Text type='secondary'>
                        <Button
                            disabled={props.activeIndex === 0}
                            icon={<LeftOutlined />}
                            size='small'
                            type='text'
                            onClick={() => dispatch(switchMessage({
                                chatId: props.chatId,
                                messageId: props.messageId,
                                index: props.activeIndex - 1,
                            }))}
                        />
                        {props.activeIndex + 1} / {props.siblingCount}
                        <Button
                            disabled={props.activeIndex === props.siblingCount - 1}
                            icon={<RightOutlined />}
                            size='small'
                            type='text'
                            onClick={() => dispatch(switchMessage({
                                chatId: props.chatId,
                                messageId: props.messageId,
                                index: props.activeIndex + 1,
                            }))}
                        />
                    </Text>
                </div>
            }
        </div>
    </div>
});

interface MessageProps {
    chatId: string;
    authorName: string;
    avatarUrl?: string;
    message: Message;
    activeIndex: number;
    siblingCount: number;
    isLoading: boolean;
    isEditing: boolean;
    setEditingMessageId: (id?: string) => void;
    isHovered: boolean;
    setHoveredMessageId: (id?: string) => void;
}

const MessageComponent = memo((props: MessageProps) => {
    const [editMessageText, setEditMessageText] = useState<string>();
    const dispatch = useAppDispatch();
    const openAI = useOpenAI();
    const python = usePython();
    const { chatId, setEditingMessageId, message } = props;
    const onSubmitEdit = useCallback(() => {
        setEditingMessageId(undefined);
        if (editMessageText) {
            dispatch(sendUserMessage(openAI, python)({
                chatId,
                parentMessageId: message.parent,
                text: editMessageText,
            }));
        }
    }, [message.parent, python, chatId, setEditingMessageId, dispatch, openAI, editMessageText]);
    return <div
        className={styles.message}
        key={message.id}
        onMouseEnter={() => props.setHoveredMessageId(message.id)}
        onMouseLeave={() => props.setHoveredMessageId(undefined)}
    >
        <MessageHeader
            chatId={chatId}
            authorName={props.authorName}
            avatarUrl={props.avatarUrl}
            messageId={message.id}
            messageAuthorKind={message.author.kind}
            activeIndex={props.activeIndex}
            siblingCount={props.siblingCount}
            isLoading={props.isLoading}
            isEditing={props.isEditing}
            setEditingMessageId={setEditingMessageId}
            isHovered={props.isHovered}
            onSubmitEdit={onSubmitEdit}
        />
        {
            (message.author.kind === 'agent' || message.author.kind === 'user') && (
                <div className={styles.messageContainer}>
                    {props.isEditing
                        ? <EditMessage
                            message={message}
                            onChange={setEditMessageText}
                        />
                        : props.message.status === 'failure'
                            ? <div className={styles.messageContent}>
                                <Text type='danger'>
                                    <Space size='small'>
                                        <CloseCircleOutlined />
                                        Oops! Something went wrong.
                                    </Space>
                                </Text>
                            </div>
                            : <MessageContent parts={message.parts} />
                    }
                </div>
            )
        }
    </div>
});

const EmptyMessages = memo(({ chatId }: { chatId: string }) => {
    const chat = useMemoSelector(() => makeSelectChat(chatId), [chatId]);
    const agentIdParam = useAgentParam();
    const agentId = chat?.entity?.agent ?? agentIdParam ?? '';
    const agent = useMemoSelector(() => makeSelectAgent(agentId), [agentId]);
    const isOmni = agentId === 'omni';
    const agentAvatarSrc = isOmni
        ? '/favicon.svg'
        : agent?.entity?.avatarUrl;
    const agentName = isOmni
        ? 'Omni'
        : agent?.entity?.name
    return <Flex
        align='center'
        justify='center'
        vertical
        style={{
            height: '100%',
            opacity: (agent || isOmni) ? 1 : 0,
            transition: 'opacity 250ms ease',
        }}
    >
        {agentAvatarSrc
            ? <Avatar src={agentAvatarSrc} size={128} />
            : <Avatar icon={<RobotOutlined />} size={128} />
        }
        {agentName &&
            <Title
                level={3}
                style={{ marginTop: 16 }}
                type='secondary'
            >
                {agentName}
            </Title>
        }
    </Flex>;
});

interface MessagesProps {
    chatId: string;
    styles?: {
        messages: React.CSSProperties;
    };
}

const Messages = memo((props: MessagesProps) => {
    const branch = useMemoSelector(() => makeSelectActiveMessageBranch(props.chatId), [props.chatId]);
    const [hoveredMessageId, setHoveredMessageId] = useState<string>();
    const [editingMessageId, setEditingMessageId] = useState<string>();
    return <div
        className={styles.messages}
        style={props.styles?.messages}
    >
        {branch.length > 0
            ? <div className={styles.messagesInner}>
                {branch.map(level => {
                    const message = level.messages[level.activeIndex];
                    return <MessageComponent
                        key={message.id}
                        chatId={props.chatId}
                        authorName={level.authorName}
                        avatarUrl={level.avatarUrl}
                        message={message}
                        activeIndex={level.activeIndex}
                        siblingCount={level.messages.length}
                        isLoading={message.status === 'in_progress'}
                        isEditing={editingMessageId === message.id}
                        setEditingMessageId={setEditingMessageId}
                        isHovered={hoveredMessageId === message.id}
                        setHoveredMessageId={setHoveredMessageId}
                    />;
                })}
            </div>
            : <EmptyMessages chatId={props.chatId} />
        }
    </div>
});

interface BottomAreaProps {
    chatId: string;
    disabled?: boolean;
    onUserSendMessage: (message: UserMessage) => void;
}

const isMessageCommit = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    return event.key === 'Enter' && !event.shiftKey;
};

const supportedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg'];

const BottomArea = (props: BottomAreaProps) => {
    const chatStatus = useMemoSelector(() => makeSelectChatStatus(props.chatId), [props.chatId]);
    const canSendMessage = useMemoSelector(() => selectHasAPIKey, []);
    const openAI = useOpenAI();
    const [textAreaValue, setTextAreaValue] = useState('');
    const disabled = props.disabled || !canSendMessage;
    const [images, setImages] = useState<UserImage[]>([]);
    const [imageHovered, setImageHovered] = useState<string | undefined>(undefined);
    const [textAreaAutoSize, setTextAreaAutoSize] = useState({ minRows: 1 as undefined | number, maxRows: 12 });
    return <div className={styles.bottomArea}>
        <div className={styles.userInputArea}>
            <div className={styles.textAreaContainer}>
                {!!images.length && <div className={styles.images}>
                    {images.map((image, index) => <div
                        className={styles.image}
                        key={image.url}>
                        <img
                            className={styles.chatImage}
                            key={index}
                            src={image.url}
                            alt={image.name}
                            onMouseEnter={() => setImageHovered(image.url)}
                            onMouseLeave={() => setImageHovered(undefined)}
                        />
                        {imageHovered === image.url && <Button
                            className={styles.imageRemoveButton}
                            type="primary"
                            shape="circle"
                            size="small"
                            icon={<CloseOutlined />}
                            danger={true}
                            onMouseEnter={() => setImageHovered(image.url)}
                            onMouseLeave={() => setImageHovered(undefined)}
                            onClick={() => {
                                setImages(images.filter((_, i) => i !== index));
                                if (images.length === 1) {
                                    setTextAreaAutoSize({
                                        ...textAreaAutoSize,
                                        minRows: textAreaAutoSize.minRows === 1 ? undefined : 1, // hack to force height recalc
                                    });
                                }
                            }}
                        />}
                    </div>)}
                </div>}
                <div className={styles.textArea}>
                    <ImageInput
                        supportedTypes={supportedTypes}
                        uploadUrl={file => `/files/${uuid()}/${file.name}`}
                        onUpload={url => {
                            setImages([...images, {
                                name: url.split('/').pop() as string,
                                url,
                            }]);
                        }}
                    >
                        <Button
                            className={styles.imageButton}
                            type="text"
                            size="large"
                            icon={<FileImageOutlined />}
                            disabled={disabled}
                        />
                    </ImageInput>
                    <TextArea
                        autoFocus={true}
                        styles={{
                            textarea: {
                                fontSize: 16,
                                padding: 12,
                                paddingTop: images.length ? 140 : 12,
                                paddingLeft: 48,
                            },
                        }}
                        disabled={disabled}
                        value={textAreaValue}
                        placeholder={canSendMessage ? 'Say something' : 'Please configure your settings to begin'}
                        onChange={event => {
                            setTextAreaValue(event.target.value);
                        }}
                        onKeyDown={event => {
                            if (!disabled && isMessageCommit(event)) {
                                event.preventDefault();
                            }
                        }}
                        onKeyUp={event => {
                            if (!disabled && isMessageCommit(event)) {
                                event.preventDefault();
                                props.onUserSendMessage(({
                                    event,
                                    text: textAreaValue,
                                    images,
                                }));
                                setTextAreaValue('');
                                setImages([]);
                            }
                        }}
                        autoSize={textAreaAutoSize}
                    />
                </div>
            </div>
            <div className={styles.actionButtons}>
                {chatStatus === 'in_progress'
                    ? <Button
                        type="text"
                        size="large"
                        icon={<StopOutlined />}
                        danger={true}
                        onClick={() => {
                            if (openAI.activeAbortController) {
                                openAI.activeAbortController.abort();
                            }
                        }}
                    />
                    : <Button
                        type="text"
                        size="large"
                        icon={<SendOutlined />}
                        disabled={disabled}
                        onClick={event => {
                            if (!disabled) {
                                props.onUserSendMessage(({
                                    event,
                                    text: textAreaValue,
                                    images,
                                }));
                                setTextAreaValue('');
                                setImages([]);
                            }
                        }}
                    />
                }
            </div>
        </div>
    </div>
};

interface ChatProps extends MessagesProps, BottomAreaProps {
}

const ChatComponent = (props: ChatProps) => {
    return <div className={styles.chat}>
        <Messages
            chatId={props.chatId}
            styles={props.styles}
        />
        <BottomArea
            chatId={props.chatId}
            disabled={props.disabled}
            onUserSendMessage={props.onUserSendMessage}
        />
    </div>
};

const Loading = () => (
    <div className={styles.loading}>
        <Skeleton active avatar paragraph={{ rows: 4 }} />
        <BottomArea
            chatId={''}
            disabled={true}
            onUserSendMessage={() => { }}
        />
    </div>
);

interface ChatInstanceProps {
    id: string;
    styles?: ChatProps['styles'];
}

export const ChatLoader = (props: ChatInstanceProps) => {
    const chat = useAppSelector(state => selectChatById(state, props.id));
    const dispatch = useAppDispatch();
    const openAI = useOpenAI();
    const python = usePython();
    return (chat?.entity && chat.status === 'fulfilled')
        ? <ChatComponent
            chatId={chat.entity.id}
            styles={props.styles}
            onUserSendMessage={message => {
                dispatch(sendUserMessage(openAI, python)({
                    chatId: chat.entity.id,
                    text: message.text,
                    images: message.images,
                }));
            }}
        />
        : <Loading />
};

const useAgentParam = () => {
    const [searchParams] = useSearchParams();
    return searchParams.get('agent') || undefined;
};

const NewChat = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const agentId = useAgentParam();
    const openAI = useOpenAI();
    const python = usePython();
    return <ChatComponent
        chatId=''
        onUserSendMessage={async (message) => {
            const createChatResult = await dispatch(createChat({
                agent: agentId,
            }));
            if (createChatResult.payload != null) {
                const chat = (createChatResult.payload as { chat: Chat }).chat;
                dispatch(sendUserMessage(openAI, python)({
                    chatId: chat.id,
                    text: message.text,
                    images: message.images,
                }));
                navigate(`/chat/${chat.id}`, { replace: true });
            }
        }}
    />
};

export interface ChatPageProps {
    id?: string;
}

const ChatPage = (props: ChatPageProps) => {
    return props.id == null
        ? <NewChat />
        : <ChatLoader id={props.id} />;
};

export default ChatPage;
