import { useEffect, useRef, useState, lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Input, Skeleton, Form, message, Button, Popover, Slider, Select, Flex, Typography, Dropdown, Spin, Divider, Drawer, Layout, Segmented, Tag, Tooltip } from 'antd';
import { ConfigProvider, ThemeConfig, theme } from 'antd';
import { CloseCircleOutlined, ThunderboltOutlined, ExperimentOutlined, RobotOutlined, QuestionCircleOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { Navigate } from 'react-router-dom';
import Editor, { type Monaco } from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import SyntaxHighlighter from 'react-syntax-highlighter';
import 'highlight.js/styles/atom-one-dark.css';

import styles from './Agent.module.css';
import { useAppDispatch, useMemoSelector } from '../../app/hooks';
import { loadAgent, loadModels, makeSelectAgent, selectAllModels, updateAgent } from './Agent.slice';
import type { Agent } from '../../contracts/agent';
import usePython from '../../modules/python';
import type { ToolDefinition } from '../../contracts/tools';
import useOpenAI from '../../modules/openai';
import { getExample, examples } from './examples';
import { toolDebugger } from '../../helpers/featureSwitches';
import { logEvent } from '../../app/firebase';
import MagicWand from '../icons/MagicWand';
import { ImageInput } from '../image-input/ImageInput';

const { TextArea } = Input;
const { Text } = Typography;
const { Content } = Layout;

const ToolAssistant = lazy(() => import('./ToolAssistant'));

const toolAssistantVersions = [
    {
        label: 'GPT-3.5',
        value: 'tool-assistant-35',
    },
    {
        label: 'GPT-4o',
        value: 'tool-assistant-4',
    },
];

interface AgentProps {
    agent: Agent;
    onUpdateAgent: (agent: Agent) => void;
}

type Writable<T> = {
    -readonly [P in keyof T]: T[P];
};

const customCodeStyle = {
    margin: 0,
    borderRadius: 0,
    fontSize: 12,
    padding: '6px 36px 6px 12px', // 36px for copy button
    width: '100%',
    display: 'flex',
    overflow: 'auto',
};

interface ImagePickerProps {
    id: string;
    dalleDescription?: string;
    url?: string;
    onChange?: (url: string) => void;
}

const supportedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg'];
const ImagePicker = (props: ImagePickerProps) => {
    const openAI = useOpenAI();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [openImageInput, setOpenImageInput] = useState(false);
    return (
        <>
            {contextHolder}
            <ImageInput
                supportedTypes={supportedTypes}
                uploadUrl={`/files/agents/${props.id}/avatar`}
                open={openImageInput}
                onUnsupportedType={() => messageApi.error('Unsupported file type. Please upload a JPEG, PNG, GIF, or SVG file.')}
                onUpload={() => props.onChange?.(`/files/agents/${props.id}/avatar`)}
                onOpen={() => setOpenImageInput(false)}
            />
            <Dropdown
                menu={{
                    items: [
                        {
                            label: 'Upload',
                            title: 'Upload an avatar image',
                            key: 'upload',
                            disabled: loading,
                        },
                        {
                            label: 'Use DALL-E',
                            title: (props.dalleDescription?.length ?? 0) < 45
                                ? 'Add a description to use DALL-E'
                                : 'Generate an avatar using DALL-E',
                            key: 'dalle',
                            disabled: loading || (props.dalleDescription?.length ?? 0) < 45,
                        },
                    ],
                    onClick: async ({ key }) => {
                        if (key === 'upload') {
                            setOpenImageInput(true);
                        } else if (key === 'dalle') {
                            setLoading(true);
                            try {
                                const response = await openAI.images.generate({
                                    prompt: `Generate an avatar for a chat assistant with the following name and description. The avatar should be friendly. \n\n${props.dalleDescription}`,
                                    model: 'dall-e-3',
                                    size: '1024x1024',
                                    style: 'vivid',
                                    response_format: 'b64_json',
                                });
                                if (response.data[0].b64_json) {
                                    const url = `/files/agents/${props.id}/avatar`;
                                    const uploadResult = await fetch(url, {
                                        method: 'PUT',
                                        body: new Blob(
                                            [Uint8Array.from(atob(response.data[0].b64_json), c => c.charCodeAt(0))],
                                            { type: 'image/png' },
                                        ),
                                        headers: {
                                            'Content-Type': 'image/png',
                                        },
                                    });
                                    if (uploadResult.ok) {
                                        props.onChange?.(`${url}?${Date.now()}`); // Force image reload
                                        setLoading(false);
                                        return;
                                    }
                                }
                            } catch { }
                            setLoading(false);
                            messageApi.error('An error occurred while generating the avatar.');
                        }
                    },
                }}
                trigger={['click', 'contextMenu']}
            >
                <Button
                    className={styles.imagePickerButton}
                    type='text'
                    disabled={loading}
                >
                    {loading
                        ? <Spin />
                        : props.url
                            ? <img src={props.url} alt="Avatar" style={{ width: 62, height: 62 }} />
                            : <RobotOutlined style={{ fontSize: 24 }} />
                    }
                </Button>
            </Dropdown>
        </>
    );
};

const AgentComponent = (props: AgentProps) => {
    const [form] = Form.useForm();
    const agentFormValues = {
        name: props.agent.name,
        description: props.agent.description,
        instructions: props.agent.instructions,
        model: props.agent.model,
        temperature: props.agent.temperature,
    };
    const ref = useRef<{ editor: editor.IStandaloneCodeEditor, monaco: Monaco }>(null);
    const python = usePython();
    const openAI = useOpenAI();
    const {
        token: { colorText },
    } = theme.useToken();
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useAppDispatch();
    const [updateCount, setUpdateCount] = useState(0);
    useEffect(() => {
        form.resetFields();
    }, [form, props.agent.id, updateCount]);
    useEffect(() => {
        const agentId = `${props.agent.id}-test`;
        requestIdleCallback(() => python.getEnvironment(agentId, agentId));
    }, [python, props.agent.id]);
    useEffect(() => {
        if (ref.current) {
            ref.current.editor.setValue(props.agent.toolContexts?.python?.source ?? '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.agent.id, updateCount /* intentionally only change on agent id change */]);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const models = useMemoSelector(() => selectAllModels);
    const setExample = async (key: typeof examples[number]['key']) => {
        if (ref.current) {
            const example = await getExample(key);
            const currentSource = ref.current.editor.getValue();
            const hasExistingValue = currentSource.trim().length > 0;
            const scrollBottom = ref.current.editor.getModel()?.getLineCount() || 0;
            const updatedEditorValue = hasExistingValue
                ? `${currentSource}\n\n\n${example}`
                : example;
            ref.current.editor.setValue(updatedEditorValue);
            if (hasExistingValue) {
                ref.current.editor.revealLineNearTop(scrollBottom + 3, editor.ScrollType.Smooth);
            }
        }
    };
    const [toolAssistantOpen, setToolAssistantOpen] = useState(false);
    const [toolAssistantVersion, setToolAssistantVersion] = useState('tool-assistant-35');
    return <div
        className={toolAssistantOpen ? `${styles.agent} ${styles.withToolAssistant}` : styles.agent}
        onKeyDown={event => {
            if (event.ctrlKey && event.key === 's') {
                event.preventDefault();
                event.stopPropagation();
                messageApi.success('Assistant updated!');
            }
        }}
    >
        <div className={styles.agentInner}>
            {contextHolder}
            <Form
                name="agent"
                form={form}
                layout="vertical"
                className={styles.agentForm}
                onValuesChange={changedValues => props.onUpdateAgent({
                    ...props.agent,
                    ...changedValues
                })}
                initialValues={agentFormValues}
            >
                <Flex gap={20}>
                    <Form.Item>
                        <ImagePicker
                            id={props.agent.id}
                            dalleDescription={`Name="${props.agent.name}"\nDescription="${props.agent.description}"`}
                            url={props.agent.avatarUrl}
                            onChange={url => {
                                dispatch(updateAgent({
                                    ...props.agent,
                                    avatarUrl: url,
                                }));
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        style={{ width: '25%' }}
                        name="name"
                        label="Name"
                        required
                    >
                        <Input placeholder="New assistant" />
                    </Form.Item>
                    <Form.Item
                        style={{ width: '75%' }}
                        name="description"
                        label="Description"
                        required
                    >
                        <TextArea
                            autoSize={{ maxRows: 4 }}
                            placeholder="The assistant's purpose"
                        />
                    </Form.Item>
                </Flex>
                <Flex gap={20}>
                    <Form.Item
                        style={{ width: '50%' }}
                        name="model"
                        label="Model"
                        required
                    >
                        <Select
                            onDropdownVisibleChange={() => {
                                if (models.length === 0) {
                                    dispatch(loadModels(openAI)());
                                }
                            }}
                            options={models.map(model => ({
                                label: model.id,
                                value: model.id,
                            }))}
                        />
                    </Form.Item>
                    <Form.Item
                        style={{ width: '50%' }}
                        name="temperature"
                        label="Temperature"
                        required
                    >
                        <Slider
                            min={0.0}
                            max={2.0}
                            step={0.01}
                        />
                    </Form.Item>
                </Flex>
                <Form.Item
                    name="instructions"
                    label="Instructions"
                    required
                >
                    <TextArea
                        autoSize={{ minRows: 4, maxRows: 20 }}
                        placeholder="Instructions for the assistant"
                    />
                </Form.Item>
            </Form>
            <Flex
                gap={4}
                align='center'
                justify='space-between'
                style={{
                    width: '100%',
                    marginBottom: 8,
                }}
            >
                <Flex gap={4}>
                    <Text>Tools</Text>
                    <Popover
                        placement="rightBottom"
                        content={
                            <div style={{ maxWidth: 310 }}>
                                <Text>
                                    Tools allow you to extend your assistant with custom behavior.
                                    A Tool is a Python function that can be called by your assistant.
                                    The function can be used to perform any action, such as making an API request, performing a calculation, or anything you can write Python code for! 🤯
                                </Text>
                                <Divider style={{ margin: '8px 0' }} />
                                <Text>
                                    Get started with the <Button
                                        type='link'
                                        style={{ padding: 0, height: 22 }}
                                        onClick={() => setExample('documentation')}
                                    >documentation</Button> or jump start with a ready made example <ArrowRightOutlined />
                                </Text>
                            </div>
                        }
                    >
                        <Button
                            size='small'
                            type='text'
                            style={{ height: 22 }}
                        >
                            <QuestionCircleOutlined />
                        </Button>
                    </Popover>
                    {errorMessage && <Popover
                        placement="rightBottom"
                        title='Oops! Something went wrong.'
                        content={<div style={{ display: 'flex' }}>
                            <SyntaxHighlighter
                                useInlineStyles={false}
                                language='json'
                                PreTag='div'
                                customStyle={customCodeStyle}
                            >
                                {errorMessage}
                            </SyntaxHighlighter>
                        </div>}>
                        <Button
                            size='small'
                            danger
                            type='text'
                            style={{ height: 22 }}
                        >
                            <CloseCircleOutlined />
                        </Button>
                    </Popover>}
                </Flex>
                <Flex gap={4}>
                    <Button
                        size='middle'
                        onClick={() => setToolAssistantOpen(!toolAssistantOpen)}
                    >
                        <Flex align='center' gap={8}>
                            Tool assistant <MagicWand size={16} />
                        </Flex>
                        <Drawer
                            title={<>
                                Tool Assistant
                                <Tag
                                    style={{ marginLeft: 8 }}
                                    color="geekblue"
                                >
                                    Beta
                                </Tag>
                                <Tooltip
                                    title="GPT-4o is more helpful, but also more expensive"
                                >
                                    <Segmented
                                        options={toolAssistantVersions}
                                        onChange={value => setToolAssistantVersion(value.toString())}
                                    />
                                </Tooltip>
                            </>}
                            open={toolAssistantOpen}
                            mask={false}
                            maskClosable={false}
                            push={true}
                            width={600}
                            onClose={() => setToolAssistantOpen(false)}
                            onClick={event => event.stopPropagation()}
                        >
                            <Content style={{
                                height: '100%',
                                color: colorText,
                            }}>
                                <Suspense fallback={<Loading />}>
                                    {toolAssistantOpen && <ToolAssistant
                                        agentId={props.agent.id}
                                        assistantId={toolAssistantVersion}
                                        getProperties={query => {
                                            const result = {} as Record<string, any>;
                                            if (query.name) {
                                                result.name = props.agent.name;
                                            }
                                            if (query.description) {
                                                result.description = props.agent.description;
                                            }
                                            if (query.temperature) {
                                                result.temperature = props.agent.temperature;
                                            }
                                            if (query.model_name) {
                                                result.model_name = props.agent.model;
                                            }
                                            if (query.instructions) {
                                                result.instructions = props.agent.instructions;
                                            }
                                            if (query.tools) {
                                                result.tools = props.agent.toolContexts.python?.source || '';
                                            }
                                            return result;
                                        }}
                                        onPropertiesChange={async (properties) => {
                                            const updateProps = {
                                                ...props.agent,
                                            };
                                            if (properties.name) {
                                                updateProps.name = properties.name;
                                            }
                                            if (properties.description) {
                                                updateProps.description = properties.description;
                                            }
                                            if (properties.temperature) {
                                                updateProps.temperature = properties.temperature;
                                            }
                                            if (properties.model_name) {
                                                updateProps.model = properties.model_name;
                                            }
                                            if (properties.instructions) {
                                                updateProps.instructions = properties.instructions;
                                            }
                                            if (properties.tools) {
                                                const agentId = `${props.agent.id}-test`;
                                                try {
                                                    await python.parseTools(agentId, agentId, properties.tools || '');
                                                    await python.getActiveTools(agentId, agentId, properties.tools || '');
                                                    updateProps.toolContexts = {
                                                        ...updateProps.toolContexts,
                                                        python: {
                                                            ...updateProps.toolContexts?.python,
                                                            source: properties.tools,
                                                        },
                                                    };
                                                } catch (error) {
                                                    if (error instanceof Error) {
                                                        return `There was an error in the tools' Python code:\n${error.message}`;
                                                    } else {
                                                        return "An error occurred while parsing the tool's Python code. Look for the error and try to correct it. Only try up to 3 times.";
                                                    }
                                                }
                                            }
                                            props.onUpdateAgent(updateProps);
                                            setTimeout(() => setUpdateCount(updateCount + 1));
                                            return `The assistant's properties were update successfully.`;
                                        }}
                                    />}
                                </Suspense>
                            </Content>
                        </Drawer>
                    </Button>
                    <Dropdown
                        menu={{
                            items: examples as Writable<typeof examples>,
                            onClick: async ({ key }) => {
                                logEvent('loadExample', {
                                    example: key,
                                });
                                setExample(key as typeof examples[number]['key']);
                            },
                        }}
                    >
                        <Button size='middle'>
                            Examples <ThunderboltOutlined />
                        </Button>
                    </Dropdown>
                </Flex>
            </Flex>
            <div
                className={errorMessage ? `${styles.editor} ${styles.editorError}` : styles.editor}
            >
                <Editor
                    height="100%"
                    defaultLanguage="python"
                    options={{
                        minimap: {
                            enabled: false,
                        },
                    }}
                    defaultValue={props.agent.toolContexts?.python?.source || ''}
                    theme='vs-dark'
                    onMount={(editor, monaco) => {
                        (ref as Writable<typeof ref>).current = { editor, monaco };
                        console.log({ editor, monaco });
                    }}
                    onChange={async (value) => {
                        let pythonToolDefs: ToolDefinition[] | undefined;
                        try {
                            const agentId = `${props.agent.id}-test`;
                            pythonToolDefs = await python.parseTools(agentId, agentId, value || '');
                            const pythonActiveTools = await python.getActiveTools(agentId, agentId, value || '');

                            if (toolDebugger && ref.current) {
                                for (const toolDef of pythonToolDefs) {
                                    const widget = createDebugWidget(toolDef);
                                    ref.current.editor.addContentWidget(widget);
                                }
                            }

                            console.log({ pythonToolDefs, pythonActiveTools });

                            setErrorMessage(undefined);
                            props.onUpdateAgent({
                                ...props.agent,
                                toolContexts: {
                                    ...props.agent.toolContexts,
                                    python: {
                                        ...props.agent.toolContexts?.python,
                                        source: value || '',
                                    },
                                },
                                tools: pythonToolDefs,
                            });
                        } catch (error) {
                            console.error(error);
                            setErrorMessage(error instanceof Error
                                ? error.message
                                : 'An error occurred while parsing the Python code.',
                            );
                        }
                    }}
                />
            </div>
        </div>
    </div>
};

const themeConfig: ThemeConfig = {
    algorithm: [theme.darkAlgorithm],
};

const createDebugWidget = (tool: ToolDefinition): editor.IContentWidget => {
    return {
        getDomNode: () => {
            if (document.getElementById(`omnince-assistant-widget-${tool.name}`)) {
                return document.getElementById(`omnince-assistant-widget-${tool.name}`)!;
            }
            const widgetRoot = document.createElement('div');
            widgetRoot.id = `omnince-assistant-widget-${tool.name}`;
            const root = createRoot(widgetRoot);
            root.render(
                <div>
                    <ConfigProvider theme={themeConfig}>
                        <Popover
                            placement="rightBottom"
                            trigger='click'
                            content={
                                <TextArea autoSize />
                            }
                        >
                            <Button
                                type='link'
                                size='small'
                                style={{
                                    position: 'absolute',
                                    top: -2,
                                }}
                            >
                                <ExperimentOutlined />
                            </Button>
                        </Popover>
                    </ConfigProvider>
                </div>
            );
            return widgetRoot;
        },
        getId: () => `omnince.assistant.widget.${tool.name}`,
        getPosition: () => ({
            position: {
                lineNumber: tool.position.line,
                column: tool.position.column + 14,
            },
            preference: [0],
        }),
    };
};

const Loading = () => (
    <div className={styles.loading}>
        <Skeleton active paragraph={{ rows: 4 }} />
    </div>
);

interface AgentLoaderProps {
    id: string;
}

const AgentLoader = (props: AgentLoaderProps) => {
    const agent = useMemoSelector(() => makeSelectAgent(props.id), [props.id]);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!agent) {
            dispatch(loadAgent(props.id));
        }
    }, [agent, props.id, dispatch]);
    return agent && agent.status === 'fulfilled'
        ? <AgentComponent
            agent={agent.entity}
            onUpdateAgent={async (agent) => {
                await dispatch(updateAgent(agent));
            }}
        />
        : agent && agent.status === 'rejected'
            ? <Navigate to="/" replace={true} />
            : <Loading />
};

export interface AgentsPageProps {
    id?: string;
}

const AgentsPage = (props: AgentsPageProps) => {
    return props.id == null
        ? <Navigate to="/" replace={true} />
        : <AgentLoader id={props.id} />;
};

export default AgentsPage;
