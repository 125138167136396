import { useEffect, useRef } from "react";

export interface ImageInputProps {
    supportedTypes: string[];
    uploadUrl: `/files/${string}` | ((file: File) => `/files/${string}`);
    open?: boolean;
    onUnsupportedType?: () => void;
    onUpload?: (url: string) => void;
    onOpen?: () => void;
    children?: React.ReactNode;
}

export const ImageInput = (props: ImageInputProps) => {

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const open = props.open;
    const onOpen = props.onOpen;
    useEffect(() => {
        if (open) {
            fileInputRef.current?.click();
            onOpen?.();
        }
    }, [open, onOpen, fileInputRef]);

    return <span
        onClick={() => {
            fileInputRef.current?.click();
            onOpen?.();
        }}
    >
        <input
            type="file"
            style={{ display: 'none' }}
            ref={fileInputRef}
            accept={props.supportedTypes.join(', ')}
            onChange={async (event) => {
                const file = event.target.files?.[0];
                if (!file) {
                    return;
                }

                if (!props.supportedTypes.includes(file.type)) {
                    props.onUnsupportedType?.();
                    return;
                }
                const url = typeof props.uploadUrl === 'function' ? props.uploadUrl(file) : props.uploadUrl;
                const response = await fetch(url, {
                    method: 'PUT',
                    body: file,
                    headers: {
                        'Content-Type': file.type,
                    },
                });
                if (response.ok) {
                    props.onUpload?.(url);
                }
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            }}
        />
        {props.children}
    </span>;
};