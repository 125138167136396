import {
    createAsyncThunk,
    createSlice,
    createEntityAdapter,
    EntityState,
    PayloadAction,
    createSelector,
} from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import type { default as OpenAI } from 'openai';

import type { OpenAI as OpenAIService } from '../../modules/openai';
import { RootState } from '../../app/store';
import type { Chat, ChatDao, Message, MessageDao, MessagePart, MessagePartImage, MessagePartMarkdown, MessagePartTool } from '../../contracts/chat';
import { getChat, getAllChats, updateChat as updateChatDao, deleteChat as deleteChatDao } from './Chat.data';
import type { Entity, EntityFulfilled } from '../../contracts/entity';
import type { Python } from '../../modules/python';
import { handleCompletion } from '../../helpers/openai';
import { toJs } from '../../helpers/pyodide';
import { logEvent } from '../../app/firebase';
import { updateAgent } from '../agent/Agent.slice';

type WithoutId<T> = Omit<T, 'id'>;
type PartialWithId<T extends { id: string }> = Partial<Omit<T, 'id'>> & Pick<T, 'id'>;

type ChatEntity = Entity<Chat>;

const chatAdapter = createEntityAdapter<ChatEntity>();
const messagePartsAdapter = createEntityAdapter<MessagePart>();

export interface ChatState {
    chats: EntityState<ChatEntity>;
    messageParts: EntityState<MessagePart>;
    // TODO: loading state + disable chat while loading
}

const mapToChatModel = (chatDao: ChatDao) => {
    const nodeList = Object.values(chatDao.nodes);
    const parts: MessagePart[] = nodeList.map(node => node.parts).flat();
    const nodes: Record<string, Message> = {};
    for (const node of nodeList) {
        nodes[node.id] = {
            ...node,
            parts: node.parts.map(part => part.id),
        };
    }
    const chat: Chat = {
        ...chatDao,
        nodes,
    };
    return {
        chat,
        parts,
    };
};

const mapToChatDao = (chat: Chat, parts: Record<string, MessagePart | undefined>): ChatDao => {
    const nodes: Record<string, MessageDao> = {};
    for (const node of Object.values(chat.nodes)) {
        nodes[node.id] = {
            ...node,
            parts: node.parts.map(partId => parts[partId]!),
        };
    }
    return {
        ...chat,
        nodes,
    };
};

const getTreeBranch = (nodes: Chat['nodes'], leafNode: string | undefined) => {
    const messages: Message[] = [];
    let activeNode = leafNode;
    while (activeNode) {
        const message = nodes[activeNode];
        if (!message) {
            break;
        }
        messages.push(message)
        activeNode = message.parent;
    }
    return messages.reverse();
};

interface MessageWithParts {
    message: Message;
    parts: MessagePart[];
}

const getTreeBranchWithParts = (parts: EntityState<MessagePart>, chat: Chat, leafNode?: string): MessageWithParts[] => {
    const messages = getTreeBranch(chat.nodes, leafNode || chat.activeNode);
    return messages.map(message => ({
        message,
        parts: message.parts.map(partId => parts.entities[partId]!)
    }));
};

export const loadChats = createAsyncThunk('chat/loadChats', async () => {
    const chatDaos = await getAllChats();
    return chatDaos.map(mapToChatModel);
});

export const loadChat = createAsyncThunk('chat/loadChat', async (id: string, { getState, rejectWithValue }) => {
    const existingChat = selectChatById(getState() as RootState, id);
    if (existingChat?.status === 'fulfilled' && existingChat?.entity) {
        return {
            chat: existingChat.entity,
            parts: [],
        };
    }
    const chat = await getChat(id);
    if (chat) {
        return mapToChatModel(chat);
    } else {
        return rejectWithValue(undefined);
    }
});

export const updateChat = createAsyncThunk('chat/updateChat', (chatUpdates: PartialWithId<Chat>, { getState, rejectWithValue }) => {
    const existingChat = selectChatById(getState() as RootState, chatUpdates.id);
    if (!existingChat?.entity || existingChat.status !== 'fulfilled') {
        return rejectWithValue(undefined);
    }
    const messageParts = (getState() as RootState).chat.messageParts;
    const chat = {
        ...existingChat.entity,
        ...chatUpdates,
    };
    if (chat.transient) {
        return;
    }
    return updateChatDao(chatUpdates.id, mapToChatDao(chat, messageParts.entities));
});

export const deleteChat = createAsyncThunk('chat/deleteChat', (id: string) => {
    return deleteChatDao(id);
});

export const createChat = createAsyncThunk('chat/createChat', async (chat: Partial<WithoutId<Chat>>, { getState, dispatch }) => {
    logEvent('createChat');
    const agents = (getState() as RootState).agents.agents.entities;
    const agent = agents[chat.agent || 'omni'];
    const rootPart = agent && {
        id: uuid(),
        kind: 'markdown',
        text: agent.entity?.instructions || '',
    } as MessagePartMarkdown;
    const createTime = Date.now();
    const rootMessage: Message = {
        id: uuid(),
        createTime,
        children: [],
        parent: '',
        author: {
            kind: 'system',
        },
        parts: rootPart ? [rootPart.id] : [],
        status: 'success',
    };
    const id = uuid();
    const newChat: Chat = {
        name: 'New chat',
        createTime,
        lastUpdatedTime: createTime,
        activeNode: rootMessage.id,
        agent: agent?.entity?.id || 'omni',
        nodes: {
            [rootMessage.id]: rootMessage,
        },
        ...chat,
        id,
    };
    // TODO: allow creation with parts?
    const messageParts = {
        ...(getState() as RootState).chat.messageParts.entities,
        ...(rootPart ? { [rootPart.id]: rootPart } : {}),
    };
    const chatDao = mapToChatDao(newChat, messageParts);
    if (!chat.transient) {
        await updateChatDao(id, chatDao);
    }
    if (agent?.entity) {
        await dispatch(updateAgent({
            ...agent.entity,
            lastUsedAt: Date.now(),
        }));
    }
    return {
        chat: newChat,
        parts: rootPart ? [rootPart] : [],
    };
});

export const addChatMessage = createAsyncThunk('chat/addChatMessage', async (payload: AddChatMessagePayload, { dispatch, getState, rejectWithValue }) => {
    const chatEntity = selectChatById(getState() as RootState, payload.chatId);
    if (!chatEntity?.entity || chatEntity.status !== 'fulfilled') {
        return rejectWithValue(undefined);
    }

    // Hydrate message and parts
    const { message, parts } = payload;
    const chat = chatEntity.entity;
    const hydratedParts = parts.map(part => ({
        ...part,
        id: uuid(),
    }) as MessagePart);
    const createTime = Date.now();
    const hydratedMessage: Message = {
        id: uuid(),
        createTime,
        children: [],
        parent: payload.parentMessageId || chat.activeNode,
        author: message.author,
        parts: hydratedParts.map(part => part.id),
        status: message.status,
    };

    const createChatMessage: CreateChatMessagePayload = {
        chatId: payload.chatId,
        message: hydratedMessage,
        parts: hydratedParts,
    };

    // Update state
    dispatch(chatSlice.actions.createChatMessage(createChatMessage));

    // Persist state changes. Empty partial chat will just pull the latest from state (with changes from above)
    await dispatch(updateChat({
        id: payload.chatId,
        lastUpdatedTime: Date.now(),
    }));

    return createChatMessage;
});

interface AddAgentResponse {
    chatId: string;
    parentMessageId?: string; // used for edit, inserts a new message under this parent. undefined => default to activeNode
}

const roleToMessageRole = {
    'user': 'user',
    'agent': 'assistant',
    'system': 'system',
} as const;

const agentChatMessagesToModelMessages = (parts: MessagePart[]) => {

    const messages: OpenAI.Chat.Completions.ChatCompletionMessageParam[] = [];

    for (let i = 0; i < parts.length; i++) {
        let part = parts[i];
        const assistantMessage: OpenAI.Chat.Completions.ChatCompletionAssistantMessageParam = part.kind === 'markdown'
            ? {
                role: 'assistant',
                content: part.text,
            }
            : {
                role: 'assistant',
                content: null,
                tool_calls: [],
            };
        messages.push(assistantMessage);

        // accumulate tool calls following either:
        // 1. assistant content via markdown
        // 2. tool call without prior content
        // all consecutive tool calls are considered part of the same assistant message
        // until a non-tool part or the end is encountered
        let j = part.kind === 'markdown' ? 1 : 0;
        for (; (i + j) < parts.length && parts[i + j].kind === 'tool'; j++) {
            part = parts[i + j] as MessagePartTool;
            (assistantMessage.tool_calls ||= []).push({
                type: 'function',
                id: part.toolCallId,
                function: {
                    name: part.toolName,
                    arguments: part.toolCallArgs || '',
                },
            });
            if (part.status === 'success') {
                messages.push({
                    role: 'tool',
                    tool_call_id: part.toolCallId,
                    content: part.result || '',
                });
            } else if (part.status === 'failure') {
                messages.push({
                    role: 'tool',
                    tool_call_id: part.toolCallId,
                    content: part.result || 'There was an unexpected error when using the tool.',
                });
            }
        }
        i += j - 1;
    }

    return messages;
};

const tryStringify = (value: unknown) => {
    try {
        return JSON.stringify(value);
    } catch (error) {
        return;
    }
};

const downloadFileAsBlob = async (url: string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise<string>((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
    });
};

const addAgentResponse = (openAI: OpenAIService, python: Python) => createAsyncThunk(
    'chat/addAgentResponse',
    async (addAgentResponse: AddAgentResponse, { dispatch, getState, rejectWithValue }) => {
        logEvent('agentResponse');
        let running = true;
        let activeMessage: CreateChatMessagePayload | undefined;
        let activeNodeId = addAgentResponse.parentMessageId;
        let unhandledError = false;
        try {
            while (running) {
                console.groupCollapsed(`addAgentResponse chatId=${addAgentResponse.chatId}, messageId=${activeMessage?.message.id}, nodeId=${activeNodeId}`);
                const chat = selectChatById(getState() as RootState, addAgentResponse.chatId);
                if (!chat?.entity || chat.status !== 'fulfilled') {
                    return rejectWithValue(undefined);
                }

                const messageParts = (getState() as RootState).chat.messageParts;
                const chatMessagesAndParts = getTreeBranchWithParts(messageParts, chat.entity, activeNodeId);
                console.log('chatMessagesAndParts', chatMessagesAndParts);

                const images = new Map((await Promise
                    .allSettled(
                        chatMessagesAndParts
                            .flatMap(messageAndParts =>
                                messageAndParts.parts.filter((part): part is MessagePartImage => part.kind === 'image')
                            )
                            .map(part => downloadFileAsBlob(part.url).then(dataUrl => [part.url, dataUrl] as const)),
                    ))
                    .filter((result): result is PromiseFulfilledResult<[string, string]> => result.status === 'fulfilled')
                    .map(result => result.value));

                const historicalMessages = chatMessagesAndParts
                    .map(messageAndParts => messageAndParts.message.author.kind === 'agent'
                        ? agentChatMessagesToModelMessages(messageAndParts.parts)
                        : {
                            // user or system messages are just flat content where each
                            // part should be aggregated
                            role: roleToMessageRole[messageAndParts.message.author.kind],
                            content: messageAndParts.parts.reduce(
                                (content, part) => {
                                    if (part.kind === 'markdown') {
                                        const lastPart = content[content.length - 1];
                                        if (content.length && lastPart.type === 'text') {
                                            lastPart.text += part.text;
                                        } else {
                                            content.push({
                                                type: 'text',
                                                text: part.text,
                                            });
                                        }
                                    } else if (part.kind === 'image') {
                                        const dataUrl = images.get(part.url);
                                        if (dataUrl) {
                                            content.push({
                                                type: 'image_url',
                                                image_url: {
                                                    url: dataUrl,
                                                    detail: 'auto',
                                                },
                                            });
                                        }
                                    }
                                    return content;
                                },
                                [] as OpenAI.Chat.Completions.ChatCompletionContentPart[],
                            ),
                        } as OpenAI.Chat.Completions.ChatCompletionMessageParam)
                    .flat(1);
                console.log('historicalMessages', historicalMessages);

                if (!activeMessage) {
                    const chatMessage = await dispatch(addChatMessage({
                        chatId: addAgentResponse.chatId,
                        parentMessageId: addAgentResponse.parentMessageId,
                        message: {
                            author: {
                                kind: 'agent',
                                agent: chat.entity.agent,
                            },
                            status: 'in_progress',
                        },
                        parts: [],
                    }));
                    activeMessage = chatMessage.payload as CreateChatMessagePayload;
                    activeNodeId = activeMessage.message.id;
                }

                let messages = historicalMessages;

                const agent = (getState() as RootState).agents.agents.entities[chat.entity.agent];
                const agentId = agent?.entity?.id as string;
                const agentPythonContext = agent?.entity?.toolContexts?.python;
                const toolRefs = agentPythonContext?.source
                    ? await python.getActiveTools(chat.entity.id, agentId, agentPythonContext.source)
                    : [];
                const pythonEnv = agentPythonContext?.source && await python.getEnvironment(chat.entity.id, agentId);
                if (pythonEnv && pythonEnv.instance.hooks.modifyMessage) {
                    try {
                        messages = toJs(await pythonEnv.instance.hooks.modifyMessage(messages), false) || [];
                        console.log('messages', messages);
                    } catch (e) {
                        console.error('modifyMessage.catch', e);
                    }
                }

                const request = openAI.chat.completions.create({
                    model: agent?.entity?.model || 'gpt-3.5-turbo-0125',
                    temperature: agent?.entity?.temperature ?? 0.25,
                    messages,
                    tools: toolRefs.length
                        ? toolRefs.filter(tool => tool.definition).map(tool => ({
                            type: 'function',
                            function: {
                                name: tool.definition!.name,
                                description: tool.definition!.description,
                                parameters: tool.definition!.schema,
                            },
                        }))
                        : undefined,
                    stream: true,
                });

                const requestStream = await request;
                openAI.activeAbortController = requestStream.controller;

                const activeMessageId = activeMessage!.message.id;
                const addPart = (part: AddMessagePartPayload['part']) => {
                    dispatch(chatSlice.actions.addMessagePart({
                        chatId: addAgentResponse.chatId,
                        messageId: activeMessageId,
                        part,
                    }));
                    const updatedChat = selectChatById(getState() as RootState, addAgentResponse.chatId);
                    const updatedParts = updatedChat?.entity?.nodes[activeMessageId].parts;
                    return updatedParts![updatedParts!.length - 1]!;
                };
                let lastPartId: string | undefined = undefined;
                const toolCallToPartId = {} as Record<string, string>;
                const completion = await handleCompletion(requestStream, {
                    onContentStart: () => {
                        lastPartId = addPart({
                            kind: 'markdown',
                            text: '',
                            status: 'in_progress',
                        } as WithoutId<MessagePartMarkdown>);
                    },
                    onContentChunk: (delta) => {
                        if (!lastPartId) {
                            lastPartId = addPart({
                                kind: 'markdown',
                                text: '',
                                status: 'in_progress',
                            } as WithoutId<MessagePartMarkdown>);
                        }
                        if (lastPartId) {
                            dispatch(chatSlice.actions.concatMessagePart({
                                id: lastPartId,
                                delta,
                            }));
                        }
                    },
                    onContentEnd: () => {
                        if (lastPartId) {
                            dispatch(chatSlice.actions.updateMessagePart({
                                id: lastPartId,
                                status: 'success',
                            }));
                        }
                    },
                    onFunctionStart: (name, id) => {
                        const toolRef = toolRefs.find(tool => tool.definition?.name === name);
                        toolCallToPartId[id] = lastPartId = addPart({
                            kind: 'tool',
                            toolCallId: id,
                            toolName: name,
                            toolDisplayName: toolRef?.displayName,
                            status: 'in_progress',
                            hidden: toolRef?.hidden,
                        } as WithoutId<MessagePartTool>);
                    },
                    onFunctionArgsChunk: (_, args) => {
                        if (lastPartId) {
                            dispatch(chatSlice.actions.updateMessagePart({
                                id: lastPartId,
                                toolCallArgs: args,
                            }));
                        }
                    },
                    onFunctionCall: async (toolCall) => {
                        const partId = toolCallToPartId[toolCall.id];
                        if (partId) {
                            dispatch(chatSlice.actions.updateMessagePart({
                                id: partId,
                                toolCallArgs: toolCall.args,
                            }));
                            try {
                                const parsedArgs = toolCall.args && JSON.parse(toolCall.args);
                                console.log('toolCall.args', toolCall.args, { parsedArgs });
                                const toolRef = toolRefs.find(tool => tool.definition?.name === toolCall.name);
                                if (toolRef) {
                                    const tool = toolRef.definition!;
                                    const toolCallArgs = tool.args.map(arg => parsedArgs?.[arg]);
                                    console.log('toolCall', toolRef, {
                                        args: tool.args,
                                        toolCall,
                                        parsedArgs,
                                        toolCallArgs,
                                    });
                                    const rawResult = tool.async
                                        ? await toolRef.instance(...toolCallArgs)
                                        : toolRef.instance(...toolCallArgs);
                                    const result = typeof rawResult === 'string'
                                        ? rawResult
                                        : tryStringify(toJs(rawResult, false));
                                    console.log(`toolCall.result`, {
                                        partId,
                                        toolRef,
                                        rawResult,
                                        result,
                                    });
                                    dispatch(chatSlice.actions.updateMessagePart({
                                        id: partId,
                                        result,
                                        status: 'success',
                                    }));
                                }
                            } catch (error) {
                                dispatch(chatSlice.actions.updateMessagePart({
                                    id: partId,
                                    result: 'There was an unexpected error when using the tool.',
                                    status: 'failure',
                                    error: error instanceof Error ? error.message
                                        : typeof error === 'string' ? error
                                            : `There was an unexpected error when using ${toolCall.name}.`,
                                }));
                                console.error('onFunctionCall.catch', {
                                    error,
                                    toolCall,
                                    partId,
                                });
                            }
                        }
                    },
                });
                openAI.activeAbortController = undefined;

                console.log('completion finished', completion, completion.finishReason);
                running = completion.finishReason === 'tool_calls';
                console.groupEnd();
            }
        } catch (error) {
            unhandledError = true;
            console.error('addAgentResponse.catch', {
                error,
                activeMessage,
                activeNodeId,
            });
            if (activeMessage) {
                dispatch(chatSlice.actions.updateMessage({
                    chatId: addAgentResponse.chatId,
                    message: {
                        id: activeMessage.message.id,
                        status: 'failure',
                    },
                }));
            }
            console.groupEnd();
        }

        if (activeMessage && !unhandledError) {
            dispatch(chatSlice.actions.updateMessage({
                chatId: addAgentResponse.chatId,
                message: {
                    id: activeMessage.message.id,
                    status: 'success',
                },
            }));
        }

        // Persist changes
        await dispatch(updateChat({
            id: addAgentResponse.chatId,
        }));
    },
);

export interface UserImage {
    name: string;
    url: string;
}

export interface SendUserMessage {
    chatId: string;
    parentMessageId?: string; // used for edit, inserts a new message under this parent. undefined => default to activeNode
    text: string;
    images?: UserImage[];
}

export const sendUserMessage = (openAI: OpenAIService, python: Python) => createAsyncThunk(
    'chat/sendUserMessage',
    async (message: SendUserMessage, { dispatch, getState, rejectWithValue }) => {
        logEvent('sendUserMessage');
        const chat = selectChatById(getState() as RootState, message.chatId);
        if (!chat?.entity || chat.status !== 'fulfilled') {
            return rejectWithValue(undefined);
        }

        if (Object.values(chat.entity.nodes).length === 1) {
            // first message, kick off auto title
            openAI.chat.completions.create({
                model: 'gpt-3.5-turbo-0125',
                messages: [
                    {
                        role: 'system',
                        content: 'Write a short title for a conversation. The title should be simple, 1 to 3 words, and preferably start with a relevant emoji. The title should be derived from this first message from the user:',
                    },
                    {
                        role: 'user',
                        content: message.text,
                    },
                ],
                temperature: .1,
                max_tokens: 32,
            }).then((completion) => dispatch(updateChat({
                id: message.chatId,
                name: completion.choices[0].message.content || chat.entity.name,
            })));
        }

        await dispatch(addChatMessage({
            chatId: message.chatId,
            parentMessageId: message.parentMessageId,
            message: {
                author: {
                    kind: 'user',
                },
                status: 'success',
            },
            parts: [
                ...(message.images || []).map(image => ({
                    kind: 'image',
                    url: image.url,
                    name: image.name,
                } as WithoutId<MessagePartImage>)),
                {
                    kind: 'markdown',
                    text: message.text,
                } as WithoutId<MessagePartMarkdown>,
            ],
        }));

        await dispatch(addAgentResponse(openAI, python)({
            chatId: message.chatId,
        }));
    },
);

export interface SwitchMessagePayload {
    chatId: string;
    messageId: string;
    index: number;
}

export const switchMessage = createAsyncThunk(
    'chat/switchMessage',
    async (switchMessage: SwitchMessagePayload, { dispatch, getState, rejectWithValue }) => {
        const chat = selectChatById(getState() as RootState, switchMessage.chatId);
        if (!chat?.entity || chat.status !== 'fulfilled') {
            return rejectWithValue(undefined);
        }
        const message = chat.entity.nodes[switchMessage.messageId];
        if (!message?.parent) {
            return rejectWithValue(undefined);
        }
        const parent = chat.entity.nodes[message.parent];
        if (!parent) {
            return rejectWithValue(undefined);
        }
        const targetMessageId = parent.children[switchMessage.index];
        if (!targetMessageId) {
            return rejectWithValue(undefined);
        }
        let activeNodeId = targetMessageId;
        while (activeNodeId && chat.entity.nodes[activeNodeId].children.length) {
            const message = chat.entity.nodes[activeNodeId];
            if (!message) {
                break;
            }
            activeNodeId = message.children[message.children.length - 1]; // traverse most recent children
        }
        await dispatch(updateChat({
            id: switchMessage.chatId,
            activeNode: activeNodeId,
        }));
    },
);

export interface RegenerateAgentMessagePayload {
    chatId: string;
    messageId: string;
}

export const regenerateAgentMessage = (openAI: OpenAIService, python: Python) => createAsyncThunk(
    'chat/regenerateAgentMessage',
    async (regenerateAgentMessage: RegenerateAgentMessagePayload, { dispatch, getState, rejectWithValue }) => {
        const chat = selectChatById(getState() as RootState, regenerateAgentMessage.chatId);
        if (!chat?.entity || chat.status !== 'fulfilled') {
            return rejectWithValue(undefined);
        }
        const message = chat.entity.nodes[regenerateAgentMessage.messageId];
        if (!message?.parent) {
            return rejectWithValue(undefined);
        }
        const parent = chat.entity.nodes[message.parent];
        if (!parent) {
            return rejectWithValue(undefined);
        }

        await dispatch(addAgentResponse(openAI, python)({
            chatId: regenerateAgentMessage.chatId,
            parentMessageId: parent.id,
        }));
    },
);

const initialState: ChatState = {
    chats: chatAdapter.getInitialState(),
    messageParts: messagePartsAdapter.getInitialState(),
};

export interface AddChatMessagePayload {
    chatId: string;
    parentMessageId?: string; // undefined => default to activeNode
    message: Pick<Message, 'author' | 'status'>;
    parts: (WithoutId<MessagePartMarkdown> | WithoutId<MessagePartTool> | WithoutId<MessagePartImage>)[];
}

export interface CreateChatMessagePayload {
    chatId: string;
    message: Message;
    parts: MessagePart[];
}

export interface AddMessagePartPayload {
    chatId: string;
    messageId: string;
    part: WithoutId<MessagePart>;
}

export interface UpdateMessagePayload {
    chatId: string;
    message: PartialWithId<Message>;
}

export type UpdateMessagePart = Partial<MessagePart> & {
    id: MessagePart['id'];
};

export interface ConcatMessagePartPayload {
    id: string;
    delta: string;
}

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        createChatMessage: (state, action: PayloadAction<CreateChatMessagePayload>) => {
            const { chatId, message, parts } = action.payload;
            const chat = state.chats.entities[chatId]?.entity;
            if (!chat) {
                return;
            }

            chat.nodes[message.id] = message;
            messagePartsAdapter.addMany(state.messageParts, parts);

            // Update parent's children
            if (message.parent) {
                const parent = chat.nodes[message.parent];
                if (parent) {
                    parent.children.push(message.id);
                }
            }

            chat.activeNode = message.id;
            chat.lastUpdatedTime = message.createTime;
        },
        addMessagePart: (state, action: PayloadAction<AddMessagePartPayload>) => {
            const { chatId, messageId, part } = action.payload;
            const chat = state.chats.entities[chatId]?.entity;
            if (!chat) {
                return;
            }
            const message = chat.nodes[messageId];
            if (!message) {
                return;
            }
            const partWithId = {
                ...part,
                id: uuid(),
            } as MessagePart;
            message.parts.push(partWithId.id);
            messagePartsAdapter.addOne(state.messageParts, partWithId);
        },
        updateMessage: (state, { payload: { chatId, message } }: PayloadAction<UpdateMessagePayload>) => {
            const chat = state.chats.entities[chatId]?.entity;
            if (!chat) {
                return;
            }
            chat.nodes[message.id] = {
                ...chat.nodes[message.id],
                ...message,
            };
        },
        updateMessagePart: (state, action: PayloadAction<UpdateMessagePart>) => {
            messagePartsAdapter.updateOne(state.messageParts, {
                id: action.payload.id,
                changes: action.payload,
            });
        },
        concatMessagePart: (state, action: PayloadAction<ConcatMessagePartPayload>) => {
            const part = state.messageParts.entities[action.payload.id];
            if (!part || part.kind !== 'markdown') {
                return;
            }
            part.text += action.payload.delta;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(loadChat.pending, (state, action) => {
                const id = action.meta.arg;
                if (!state.chats.entities[id]) {
                    chatAdapter.addOne(state.chats, {
                        status: 'pending',
                        id,
                        entity: undefined,
                    });
                }
            })
            .addCase(loadChat.fulfilled, (state, action) => {
                const id = action.meta.arg;
                if (action.payload && state.chats.entities[id]?.status === 'pending') {
                    chatAdapter.upsertOne(state.chats, {
                        status: 'fulfilled',
                        id,
                        entity: action.payload.chat,
                    });
                    messagePartsAdapter.addMany(state.messageParts, action.payload.parts);
                }
            })
            .addCase(loadChat.rejected, (state, action) => {
                const id = action.meta.arg;
                if (state.chats.entities[id]?.status === 'pending') {
                    chatAdapter.upsertOne(state.chats, {
                        status: 'rejected',
                        id,
                        entity: undefined,
                        error: {
                            message: '',
                            code: '',
                        },
                    });
                }
            });

        builder
            .addCase(updateChat.fulfilled, (state, action) => {
                const chat = state.chats.entities[action.meta.arg.id];
                if (chat?.status === 'fulfilled') {
                    chatAdapter.updateOne(state.chats, {
                        id: action.meta.arg.id,
                        changes: {
                            status: 'fulfilled',
                            id: action.meta.arg.id,
                            entity: {
                                ...chat.entity,
                                ...action.meta.arg,
                            },
                        },
                    });
                }
            });

        builder
            .addCase(deleteChat.fulfilled, (state, action) => {
                chatAdapter.removeOne(state.chats, action.meta.arg);
            });

        builder
            .addCase(createChat.fulfilled, (state, action) => {
                chatAdapter.addOne(state.chats, {
                    status: 'fulfilled',
                    id: action.payload.chat.id,
                    entity: action.payload.chat,
                });
                messagePartsAdapter.addMany(
                    state.messageParts,
                    action.payload.parts,
                );
            });

        builder
            .addCase(loadChats.fulfilled, (state, action) => {
                chatAdapter.setAll(state.chats, action.payload.map(({ chat }) => ({
                    status: 'fulfilled',
                    id: chat.id,
                    entity: chat,
                })));
                messagePartsAdapter.addMany(
                    state.messageParts,
                    action.payload.flatMap(({ parts }) => parts),
                );
            });
    },
});

export const selectChat = (state: RootState) => state.chat;

export const {
    selectAll: selectAllChats,
    selectById: selectChatById,
} = chatAdapter.getSelectors((state: RootState) => state.chat.chats);

export const makeSelectAllChats = () => createSelector(
    (state: RootState) => selectAllChats(state),
    chats => chats
        .filter((chat): chat is EntityFulfilled<Chat> => chat.status === 'fulfilled' && !!chat.entity && !chat.entity.transient)
        .sort((a, b) => b.entity.lastUpdatedTime - a.entity.lastUpdatedTime),
);

export const makeSelectChat = (id: string) => createSelector(
    (state: RootState) => selectChatById(state, id),
    chat => chat,
);

export const {
    selectAll: selectAllMessageParts,
    selectById: selectMessagePartById,
} = messagePartsAdapter.getSelectors((state: RootState) => state.chat.messageParts);

export const makeSelectMessagePartById = (id: string) => createSelector(
    (state: RootState) => selectMessagePartById(state, id),
    messagePart => messagePart,
);

export interface MessageSlot {
    authorName: string;
    avatarUrl?: string;
    activeIndex: number;
    messages: Message[];
}

export const makeSelectActiveMessageBranch = (chatId: string) => createSelector(
    (state: RootState) => selectChatById(state, chatId)?.entity?.nodes,
    (state: RootState) => selectChatById(state, chatId)?.entity?.activeNode,
    (state: RootState) => state.agents.agents.entities[selectChatById(state, chatId)?.entity?.agent || 'omni'],
    (nodes, activeNode, agent) => {
        if (!nodes || !activeNode) {
            return [];
        }
        const mainBranch = getTreeBranch(nodes, activeNode);
        return mainBranch
            .filter(node => node.author.kind !== 'system')
            .map(node => {
                if (node.parent) {
                    const parent = nodes[node.parent];
                    const activeIndex = parent.children.indexOf(node.id);
                    const messages = parent.children.map(childId => nodes[childId]);
                    return {
                        authorName: node.author.kind === 'agent'
                            ? agent?.entity?.name || 'Omnince'
                            : 'You',
                        avatarUrl: node.author.kind === 'agent'
                            ? agent?.entity?.avatarUrl
                            : undefined,
                        activeIndex,
                        messages,
                    } as MessageSlot;
                }
                return undefined;
            })
            .filter(x => !!x) as MessageSlot[];
    },
);

export const makeSelectChatStatus = (chatId: string) => createSelector(
    (state: RootState) => selectChatById(state, chatId)?.entity?.nodes,
    (state: RootState) => selectChatById(state, chatId)?.entity?.activeNode,
    (nodes, activeNode) => {
        if (!nodes || !activeNode) {
            return undefined;
        }
        const mainBranch = getTreeBranch(nodes, activeNode);
        const lastMessage = mainBranch[mainBranch.length - 1];
        return lastMessage.status;
    },
);

export default chatSlice.reducer;
