
import type { AppSettings } from "../../contracts/settings";
import { LazyPromise } from "../../helpers/lazyPromise";
import { PersistentMemoryDB } from "../../helpers/persistentMemoryDB";

const settingsDb = new LazyPromise(async () => {
    const db = new PersistentMemoryDB('AppSettings');
    await db.isReady();
    return db;
});

const settingsKey = 'app';

export const getSettings = async () => {
    const db = await settingsDb;
    const settings = db.get<AppSettings>(settingsKey) || {
        openaiApiKey: '',
    } as AppSettings;
    return settings;
};

export const updateSettings = async (settings: AppSettings) => {
    const db = await settingsDb;
    return await db.set(settingsKey, settings);
}
