import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import chatReducer from '../components/chat/Chat.slice';
import settingsReducer from '../components/settings/Settings.slice';
import agentsReducer from '../components/agent/Agent.slice';

export const store = configureStore({
    reducer: {
        agents: agentsReducer,
        chat: chatReducer,
        settings: settingsReducer,
    },
    middleware: (getDefaultMiddleware) => {
        const middleware = getDefaultMiddleware();
        const isDevMode = process.env.NODE_ENV === 'development';
        if (isDevMode) {
            middleware.push(createLogger({
                collapsed: true,
            }));
        }
        return middleware;
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
